import React from 'react'
import AdminLayout from '../../components/admin/AdminLayout'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next';
import AllResource from '../../components/admin/AllResource';
import PendingResource from '../../components/admin/PendingResource';

function Resource() {
        const {t} = useTranslation()
        const [value, setValue] = React.useState('1');
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
  return (
    <AdminLayout>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={t('View Resource')} value="1" />
                        <Tab label={t('Pending Resource')} value="2" />
                    </TabList>
                </Box>
                    <TabPanel value="1"><AllResource /></TabPanel>
                    <TabPanel value="2"><PendingResource /></TabPanel>
                </TabContext>
    </AdminLayout>
  )
}

export default Resource