import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAllExamsPending } from "../../hooks/useAllExamsPending";

export default function ExamsCategoriesPendingByAdmin() {
    const { t } = useTranslation();

    const columns = [
        { id: "teacher", label: t("teacher"), minWidth: 150 },
        { id: "price", label: t("price"), minWidth: 150 },
        { id: "currency", label: t("currency"), minWidth: 150 },
        { id: "subject", label: t("subject"), minWidth: 150 },
        { id: "actions", label: t("actions"), minWidth: 150 }
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { data, isLoading } = useAllExamsPending();
    const [categories, setCategoires] = useState([]);

    useEffect(() => {
        console.log(data?.data);
        if (data?.data) {
            setCategoires(data.data);
        }
    }, [data]);

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const lang = Cookies.get("i18next") || "en";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    function filterTeachers(id) {
        const filteredTeachers = categories.filter(
            (teacher) => teacher.id.toString() !== id.toString()
        );
        setCategoires(filteredTeachers);
    }

    const handleAccept = async (id) => {
        closeSnackbar();
        try {
            await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/status/accept-exam/${id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );
            enqueueSnackbar(t("Activation process was successful."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("Activation failed."), { variant: "error", autoHideDuration: 8000 });
        }
    };
    const handleReject = async (id) => {
        closeSnackbar();
        try {
            await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/delete-exams/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );

            enqueueSnackbar(t("The Exam offer has been declined."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("An error occurred while declining the Exam offer."), { variant: "error", autoHideDuration: 8000 });
        }
    };


    return (
        <Box>
            {!isLoading ? (
                <Paper sx={{ width: "100%", padding: "20px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={"center"}
                                        style={{ top: 57, minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {categories
                                    ?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" key={row.id + "demj"}>
                                                <TableCell align="center">{row.teacher.firstName + " " + row.teacher.lastName}</TableCell>
                                                <TableCell align="center">{row.price}</TableCell>
                                                <TableCell align="center">{t(row.currency)}</TableCell>
                                                {
                                                    lang === "ar" ?
                                                        <>
                                                            <TableCell align="center">{row.subject.titleAR}</TableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <TableCell align="center">{row.subject.titleEN}</TableCell>
                                                        </>
                                                }
                                                <TableCell align="center">
                                                    <Button color="success">
                                                        <DoneIcon onClick={() => handleAccept(row.id)} />
                                                    </Button>
                                                    <Button
                                                        color="error"
                                                        onClick={() => handleReject(row.id)}
                                                    >
                                                        <ClearIcon />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={categories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Loading />
            )}
        </Box>
    );
}
