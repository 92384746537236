import { Box, CardMedia, Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
export default function AboutPackage({ packageData }) {
    console.log(packageData);

    const { t } = useTranslation()
    const lang = Cookies.get("i18next") || "en";
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
      }
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <CardMedia
                component="img"
                height="140"
                image={packageData.image ? `${process.env.REACT_APP_API_KEY}images/${packageData.image}` : "/logo.png"}
                alt="package img"
                sx={{ filter: "brightness(50%)", height: "400px" }}
            />
                <Typography
                   variant="h4"
                    sx={{ marginBottom: "8px",marginTop:"8px", fontWeight: "700" }}
                >
                    {
                        lang==="ar"?
                        <>{packageData.titleAr}</>
                        :
                        <>{packageData.titleEn}</>
                    }
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "15px", fontWeight: "400"}}
                >
                    {
                        lang==="ar"?
                        <>{packageData.descriptionAr}</>
                        :
                        <>{packageData.descriptionEn}</>
                    }
                </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("level")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                     {
                        lang==="ar"?
                        <>{packageData.level.titleAR}</>
                        :
                        <>{packageData.level.titleEN}</>
                    }  
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("packageType")}
                </Typography>
                <Typography
                    sx={{ fontSize: "20px", marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                     {t(packageData.packageType)}  
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center" }}
                >
                    {t("Package Price")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {packageData.price} {t(packageData.currency)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center" }}
                >
                    {t("startDate")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {convertDate(packageData.startDate)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center" }}
                >
                    {t("End Date")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center",color:"#ec7063" }}
                >
                     {convertDate(packageData.endDate)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center" }}
                >
                    {t("sharesCount")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {packageData.numberOfShares}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",maxWidth:"300px",textAlign:"center" }}
                >
                    {t("sharesCountInWeek")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",minWidth:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {packageData.sharesInWeek}
                </Typography>
            </Box>
        </Paper>

    )
}
