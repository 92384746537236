import {useQuery} from 'react-query'

async function getQuestionsAccepted()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/status/accept-questions`
    )
    return response.json()
}

export const useAllQuestionsAccepted = ()=>
{
    return useQuery('get-accepted-questions',()=>getQuestionsAccepted())
}