import {
    Box,
    Button,
    Container,
    DialogActions,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import AdminLayout from "./AdminLayout";
import currencies from "../../data/currencies";

export default function EditLectureByAdmin() {
    const { t } = useTranslation();
    const {id}=useParams();
    console.log(id);
    
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const { teacher } = useSelector((state) => state.teacher);
    const [file, setFile] = useState();
    const [lecture, setLecture] = useState([]);


    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: "",
            price: "",
            currency: "",
            image: ""
        },
    });
 useEffect(() => {
    const fetchLevels = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/${id}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data.data);
            
            setLecture(data.data);
        } catch (err) {
            console.error("Error fetching levels:", err);
        }
    };
    fetchLevels();
 },[id])
    useEffect(() => {
        if (lecture) {
            reset({
                titleArabic: lecture.titleArabic,
                titleEnglish: lecture.titleEnglish,
                descriptionArabic: lecture.descriptionArabic,
                descriptionEnglish: lecture.descriptionEnglish,
                price: lecture.price,
                currency: lecture.currency,
                image: lecture.image
            });
        }
    }, [lecture, reset]);

    function handleFile(e) {
        setFile(e.target.files[0]);
        console.log(file);

    }
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
   
    const handleClose=()=>{
        navigate("/admin/lectures")
    }
    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();
        if(file)
        formData.append("image", file);
        formData.append("teacherId", lecture.teacherId);
        for (const key in data) {
            if (key !== "image" && key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }
        console.log([...formData]);

        try {
            await axios.put(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/edit-lecture/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("The lecture has been modified."), { variant: "success", autoHideDuration: 8000 });
            navigate("/admin/lectures")
        } catch (err) {
            enqueueSnackbar(t("Something went wrong"), { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <AdminLayout>
            <Container sx={{ marginTop: '10px', marginBottom: '80px' }}>
            <h1 className="text-3xl text-center my-4">{t("Edit Lecture")}</h1>
                <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "50%", margin: 'auto' }}>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleAr")}
                        </InputLabel>
                        <Controller
                            name="titleArabic"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleArabic", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleArabic?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleEn")}
                        </InputLabel>
                        <Controller
                            name="titleEnglish"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleEnglish", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleEnglish?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("price")}
                        </InputLabel>
                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("price", {
                                required: "price Address is required",
                            })}
                        />
                        {errors.price?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("currency")}
                        </InputLabel>
                        <Controller
                            name="currency"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    fullWidth
                                    {...register("currency", {
                                        required: t("isRequired"),
                                    })}
                                >
                                    {
                                        currencies.map((curr) => {
                                            return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                        })
                                    }
                                </Select>
                            )}
                            rules={{ required: t("required") }}

                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionArabic")}
                        </InputLabel>
                        <Controller
                            name="descriptionArabic"
                            control={control}
                            render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                            {...register("descriptionArabic", {
                                required: "description Address is required",
                            })}
                        />
                        {errors.descriptionArabic?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionEnglish")}
                        </InputLabel>
                        <Controller
                            name="descriptionEnglish"
                            control={control}
                            render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                            {...register("descriptionEnglish", {
                                required: "description Address is required",
                            })}
                        />
                        {errors.descriptionEnglish?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("chooseImage")}
                        </InputLabel>
                        <input type="file" onChange={handleFile} />

                    </Box>
                    <DialogActions>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ ml: "6px", mr: "6px" }}
                        >
                            {t("save")}
                        </Button>
                        <Button onClick={handleClose} color="error">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                </form>
            </Container>

        </AdminLayout>

    );
}

