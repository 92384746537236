import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import Loading from "../../components/Loading";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Navbar from "../../components/Navbar";
import { Delete, Edit, Visibility} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

export default function PendingResource() {
  const lang = Cookies.get("i18next") || "en";
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [resource, setResource] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    
    async function getAdminResources() {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}api/v1/admin/resources/UnVerified`,
          { signal }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        setResource(data.data);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch admin resources:", err);
          setError("Failed to fetch resources");
        }
      } finally {
        setLoading(false);
      }
    }

    getAdminResources();

    return () => {
      controller.abort(); // Cleanup to avoid memory leaks
    };
  }, []);

 
  const columns = [
    { id: "#", label: t("#"), minWidth: 150 },
    { id: "Type of Resource", label: t("Type of Resource"), minWidth: 150 },
    { id: "price", label: t("price"), minWidth: 150 },
    { id: "status", label: t("status"), minWidth: 150 },
    { id: "actions", label: t("actions"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  async function acceptResource(id) {
    closeSnackbar();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/resources/verify/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    console.log(response);
    
      if (!response.ok) {
        enqueueSnackbar(t("The Resource has been not verified."), {
          variant: "error",
          autoHideDuration: 8000,
        });
      }else{
      enqueueSnackbar(t("The Resource has been verified."), {
        variant: "success",
        autoHideDuration: 8000,
      });

      filterTeachers(id);

      }



    } catch (error) {
      enqueueSnackbar(t("The Resource has been verified."), {
        variant: "error",
        autoHideDuration: 8000,
      });
    }
  }

  async function rejectResource(id) {
    closeSnackbar();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/resources/delete-resource/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        enqueueSnackbar(t("The Resource has been not removed."), {
          variant: "error",
          autoHideDuration: 8000,
        });
      }else
      {
        enqueueSnackbar(t("The Resource has been removed."), {
          variant: "success",
          autoHideDuration: 8000,
        });
  
        filterTeachers(id);
      }

    } catch (error) {
      enqueueSnackbar(t("The Resource has been removed."), {
        variant: "error",
        autoHideDuration: 8000,
      });
    }
  }

  function filterTeachers(id) {
    const filteredTeachers = resource.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setResource(filteredTeachers);
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "0px",
        }}
      >
      </Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {resource.length > 0 &&
                  resource
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id + "demj"}>
                              <TableCell align="center">
                                {t(row.id)}
                              </TableCell>
                          <TableCell align="center">{t(row.resource)}</TableCell>
                          <TableCell align="center">{row.price}</TableCell>
                          {row.isVerified ? <>
                            <TableCell align="center" >
                              {t("Available")}
                            </TableCell>
                          </> : <>
                            <TableCell align="center">
                              {t("Review")}
                            </TableCell>
                          </>}
                          <TableCell align="center">
                            <Button color="success">
                              <DoneIcon 
                              onClick={() => acceptResource(row.id)} 
                              />
                            </Button>
                            <Button
                              color="error"
                              onClick={() => rejectResource(row.id)}
                            >
                              <ClearIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={resource.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </>
  );
}