import {useQuery} from 'react-query'

async function getPendingDiscounts()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/status/pending-discounts`
    )
    return response.json()
}

export const useMyPendingDiscounts = ()=>
{
    return useQuery('get-pending-discounts',()=>getPendingDiscounts())
}