import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import { t } from "i18next";
import JobCard from './JobCard';

function JobsData() {
  const [subjects, setAllSubjects] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const response = await instance.get("job/accept-job");
      const data = response.data.data || [];
      setAllSubjects([...data]);
    })();
  }, []);
  console.log(subjects.length);
  
  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ width: '100%' ,marginBottom:"80px"}}>
        <h1 className='text-center text-5xl mb-10 mt-40'>{t("Jobs")}</h1>
         {
          (!subjects.length) ?
            <h1 className='text-2xl text-sky-500 mt-5 text-center' style={{marginBottom:"30rem"}}>
              {t("no jobs")}
            </h1>
            :
                subjects.map((subject) => {
                    return <JobCard Jobs={subject} />
                })
        }
      </Box>
    </Container>
  )
}
export default JobsData