import { useQuery } from "react-query";

async function getMyTeacherLectures(teacherId) {
    
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/single-teacher/${teacherId}`)

    return response.json()
}
export const useMyTeacherLectures = (teacherId) => {
    return useQuery(["get-teacher-mypackages",teacherId], () =>
        getMyTeacherLectures(teacherId)
    );
};
