import SearchIcon from '@mui/icons-material/Search';
import { Paper, InputBase, IconButton } from '@mui/material';
import { t } from 'i18next';

function SearchBar({ setValue, text }) {
  function packageSearch(e) {
    setValue(e.target.value.toLowerCase());
  }
  return (
    <Paper
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%", marginTop: "50px" }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t(text)}
        onChange={(e) => { packageSearch(e) }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}

export default SearchBar
