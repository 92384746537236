import {
    Box,
    Button,
    Container,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import countries from "../../../data/countries";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar";
import LinksFooter from "../home/LinksFooter";
import Footer from "../home/Footer";

function JobDoc() {
    const navigate = useNavigate();
    const {email}=useParams()
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [file, setFile] = useState();
    const [snackbarOpenError, setSnackbarOpenError] = useState(false);
    const { t } = useTranslation();
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            advertiserName: "",
            email: email,
            phoneNumber: "",
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: "",
            country: "",
        },
    });
    function handleFile(e) {
        setFile(e.target.files[0]);
    }

    const onSubmit = async (data) => {
        const formData = new FormData();

        formData.append("image", file);
        for (const key in data) {
            if (key !== "image") {
                formData.append(key, data[key]);
            }
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/job`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200 || response.status === 201) {
                setSnackbarOpen(true);
                setTimeout(() => (
                    navigate('/')
                ), 3000)
            } else {
                setSnackbarOpenError(true);
            }
        } catch (error) {
            setSnackbarOpenError(true);
        }
    };


    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
        setSnackbarOpenError(false);
    };

    return (
        <>

            <Navbar>
                <>
                    <Container sx={{ marginTop: "110px", display: "flex", textAlign: "center" }}>
                        <Paper
                            sx={{
                                width: { md: "600px" },
                                padding: "30px 50px",
                                margin: "60px auto 70px",
                            }}
                        >
                            <Typography
                                sx={{
                                    marginTop: "20px",
                                    fontSize: "14px",
                                    textAlign: "right",
                                    fontWeight: "700",
                                    marginBottom: "20px",
                                    cursor: "pointer",
                                    color: "#5dade2",
                                }}
                                onClick={() => navigate("/guest")}
                            >
                                {t("Back")}
                            </Typography>
                            <Typography
                                sx={{
                                    marginTop: "20px",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    fontWeight: "700",
                                    marginBottom: "20px",
                                }}
                            >
                                {t("Job Announcement")}
                            </Typography>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ marginBottom: "30px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("Advertiser Name")}
                                    </InputLabel>
                                    <Controller
                                        name="advertiserName"
                                        control={control}
                                        render={({ field }) => <TextField type="text" {...field} fullWidth />}
                                        {...register("advertiserName", {
                                            required: "Advertiser Name is required",
                                        })}
                                    />
                                    {errors.advertiserName && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "30px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("title Arabic")}
                                    </InputLabel>
                                    <Controller
                                        name="titleArabic"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} fullWidth />
                                        )}
                                        {...register("titleArabic", {
                                            required: "title Arabic is required",
                                        })}
                                    />
                                    {errors.titleArabic?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "30px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("title English")}
                                    </InputLabel>
                                    <Controller
                                        name="titleEnglish"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} fullWidth />
                                        )}
                                        {...register("titleEnglish", {
                                            required: "title English is required",
                                        })}
                                    />
                                    {errors.titleEnglish?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "30px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("description Arabic")}
                                    </InputLabel>
                                    <Controller
                                        name="descriptionArabic"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField  {...field}   rows={3} multiline fullWidth />
                                        )}
                                        {...register("descriptionArabic", {
                                            required: "description Arabic Address is required",
                                        })}
                                    />
                                    {errors.descriptionArabic?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "30px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("description English")}
                                    </InputLabel>
                                    <Controller
                                        name="descriptionEnglish"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField  {...field}   rows={3} multiline fullWidth />
                                        )}
                                        {...register("descriptionEnglish", {
                                            required: "description English Address is required",
                                        })}
                                    />
                                    {errors.descriptionEnglish?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "26px", direction: "rtl" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("phone")}
                                    </InputLabel>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        render={({ field }) => <PhoneInput   {...field} />}
                                        {...register("phoneNumber", {
                                            required: "phone Number is required",
                                        })}
                                    />
                                    {errors.phoneNumber?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "26px" }}>
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                        {t("country")}
                                    </InputLabel>
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select {...field} fullWidth>
                                                {
                                                    countries.map((country) => (
                                                        <MenuItem value={country.name_en}>{t(country.name_ar)}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                        {...register("country", {
                                            required: "Country is required",
                                        })}
                                    />
                                    {errors.country?.type === "required" && (
                                        <Typography
                                            color="error"
                                            role="alert"
                                            sx={{ fontSize: "13px", marginTop: "6px" }}
                                        >
                                            {t("required")}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ marginBottom: "26px" }}>
                                    <InputLabel
                                        sx={{
                                            marginBottom: "6px",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            color: "#333"
                                        }}
                                    >
                                        {t("ImageGest")}
                                    </InputLabel>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                            backgroundColor: "#1976d2",
                                            color: "#fff",
                                            "&:hover": {
                                                backgroundColor: "#1565c0",
                                            },
                                            textTransform: "none",
                                        }}
                                    >
                                        {t("chooseImage")}
                                        <input
                                            type="file"
                                            hidden
                                            onChange={handleFile}
                                        />
                                    </Button>
                                </Box>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    type="submit"
                                    sx={{ textTransform: "capitalize" }}
                                >
                                    {t("Add a job ad now")}
                                </Button>
                            </form>
                        </Paper>
                    </Container>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        message={t("تم إرسال إعلان الوظيفة بنجاح!")}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    />
                    <Snackbar
                        open={snackbarOpenError}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        message={t("فشل نشر الوظيفة !!")}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    />
                </>
                <LinksFooter />
                <Footer />
            </Navbar>
        </>

    );
}

export default JobDoc;
