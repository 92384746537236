import { Button, Container, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import AboutSingleTeacher from "../../components/client/singleTeacher/AboutSingleTeacher";
import AvailablitySingleTeacher from "../../components/client/singleTeacher/AvailablitySingleTeacher";
import HeaderSingleTeacher from "../../components/client/singleTeacher/HeaderSingleTeacher";
import ResumeSingleTeacher from "../../components/client/singleTeacher/ResumeSingleTeacher";
import StdeuntsTypeSingleTeacher from "../../components/client/singleTeacher/StdeuntsTypeSingleTeacher";
import Navbar from "../../components/Navbar";
import { useSingleTeacher } from "../../hooks/useSingleTeacher";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import RatingTeacher from "../../components/client/singleTeacher/RatingTeacher";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import PayingTeacher from "../../components/client/singleTeacher/PayingTeacher";
import axios from "axios";
import LectureSingleTeacher from "../../components/client/singleTeacher/LectureSingleTeacher";
import { ExamSingleTeacher } from "../../components/client/singleTeacher/ExamSingleTeacher";
import PackageSingleTeacher from "../../components/client/singleTeacher/PackageSingleTeacher";
import DicountSingleTeacher from "../../components/client/singleTeacher/DicountSingleTeacher";

export default function SingleTeacher() {
  const { id, packageId } = useParams();
  const { currency } = useSelector((state) => state.currency);
  const { data, isLoading } = useSingleTeacher(id, currency);
  const { student } = useSelector((state) => state.student);
  const [teacherData,setTeacherData]=useState()
  const { t } = useTranslation();
  const navigate = useNavigate();
   useEffect(() => {
       const fachTeacher=async()=>{
         const res=await axios.get(
           `${process.env.REACT_APP_API_KEY}api/v1/teacher/getSingleTeacher/${id}`,
           {
             headers: {
               "Content-Type": "multipart/form-data",
             },
           }
         );
         console.log(res.data);
         
         setTeacherData(res.data)
       }
       fachTeacher()
     }, []);
  const handleCreateMessage = async () => {
    if (!student) {
      swal({ text: t("login_as_student"), icon: "error", button: t("ok") });
      return;
    }
    navigate(`/student/messages`);
  };

  const handleRequestBook = async () => {
    if (!student) {
      swal({ text: t("login_as_student"), icon: "error", button: t("ok") });
      return;
    }
    navigate(`/book/${data?.data.id}`);
  };
  function handleRequestPackage() {
    if (!student) {
      swal({ text: t("login_as_student"), icon: "error", button: t("ok") });
      return;
    }
    navigate(`/request-package/${data?.data.id}/${packageId}`);
  }
console.log(teacherData?.data);

  return (
    <Navbar>
      {isLoading ? (
        <Loading />
      ) : (
        <Container sx={{ marginBottom: "40px", marginTop: "80px" }}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={8}>
              <HeaderSingleTeacher teacher={teacherData?.data} />
              <AboutSingleTeacher teacher={teacherData?.data} />
              <StdeuntsTypeSingleTeacher teacher={teacherData?.data} />
              <AvailablitySingleTeacher teacher={teacherData?.data} />
              <RatingTeacher rates={teacherData?.data.Rates} teacher={teacherData} />
              <PayingTeacher teacher={teacherData?.data} />
              <ResumeSingleTeacher teacher={teacherData?.data} />
              {teacherData?.data.lectures?.length>0?<LectureSingleTeacher teacher={teacherData?.data.lectures}/>:""}
               {teacherData?.data.packages?.length>0?<PackageSingleTeacher teacher={teacherData?.data.packages}/>:""}
               {teacherData?.data.exams?.length>0?<ExamSingleTeacher  exams={teacherData?.data.exams} subjects={teacherData?.data.subjects}/>:""}

               {teacherData?.data.discounts?.length>0?<DicountSingleTeacher teacher={teacherData?.data.discounts}/>:""}
              
              
            </Grid>
            <Grid item md={12} lg={4}>
              <Paper sx={{ padding: "24px 12px", marginY: "30px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{ textTransform: "capitalize", marginBottom: "16px" }}
                  onClick={handleRequestBook}
                >
                  {t("requestBook")}
                </Button>
                {
                  packageId ? <Button
                    variant="contained"
                    fullWidth
                    sx={{ textTransform: "capitalize", marginBottom: "16px" }}
                    onClick={handleRequestPackage}
                  >
                    {t("requestPackage")}
                  </Button> : ""
                }
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleCreateMessage}
                >
                  {t("contactTeacher")}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </Navbar>
  );
}
