import {useQuery} from 'react-query'

async function getExams(teacherId)
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/single-teacher/${teacherId}`)
    return response.json()
}

export const useAllExams = (teacherId)=>
{
    return useQuery(['get-teacher-exams',teacherId],()=>getExams(teacherId))
}