import {
    Box,
    Button,
    Container,
    DialogActions,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading from "../Loading";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import currencies from "../../data/currencies";
import AdminLayout from "./AdminLayout";

export default function EditExamByAdmin() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [subjects, setSubject] = useState([]);
    const { teacher } = useSelector((state) => state.teacher);
    const navigate = useNavigate();
    const [exam, setExam] = useState()
    const lang = Cookies.get("i18next") || "en";
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {
            price: "",
            subject: "",
            currency: ""
        },
    });

    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        const getSubjects = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_KEY}api/v1/subject/allSubjects`);
            setSubject(response.data.data);
            setLoading(true)
        };
        getSubjects()
    }, [subjects, lang]);

    useEffect(() => {
        const fetchExam = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data.data);
                setExam(data.data);
            } catch (err) {
                console.error("Error fetching levels:", err);
            }
        };
        fetchExam();
    }, [id])
    useEffect(() => {
        if (exam) {
            reset({
                price: exam.price,
                subject: exam.subject,
                currency: exam.currency
            });
        }
    }, [exam, reset]);

    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();
        formData.append("teacherId", exam.teacherId);
        for (const key in data) {
            if (key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }
        console.log([...formData]);

        try {
            await axios.put(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/edit-exam/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar("exam has been updated.", { variant: "success", autoHideDuration: 8000 });
            navigate("/admin/exams")
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <AdminLayout>
                            <Container sx={{ marginTop: '20px', marginBottom: '80px' }}>
                                <h1 className="text-3xl text-center my-4">{t("Edit Exam")}</h1>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ marginBottom: "18px" }}>
                                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                            {t("price")}
                                        </InputLabel>
                                        <Controller
                                            name="price"
                                            control={control}
                                            render={({ field }) => <TextField {...field} fullWidth />}
                                            {...register("price", {
                                                required: "title Address is required",
                                            })}
                                        />
                                        {errors.price?.type === "required" && (
                                            <Typography
                                                color="error"
                                                role="alert"
                                                sx={{ fontSize: "13px", marginTop: "6px" }}
                                            >
                                                {t("required")}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ marginBottom: "18px" }}>
                                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                            {t("subject")}
                                        </InputLabel>
                                        <Controller
                                            name="subject"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    fullWidth
                                                    {...register("subject", {
                                                        required: t("isRequired"),
                                                    })}
                                                >
                                                    {lang === "ar"
                                                        ? subjects.map((subject, index) => (
                                                            <MenuItem key={index} value={subject.id}>{t(subject.titleAR)}</MenuItem>
                                                        ))
                                                        : subjects.map((subject, index) => (
                                                            <MenuItem key={index} value={subject.id}>{t(subject.titleEN)}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            )}
                                            rules={{ required: t("required") }}
                                        />

                                    </Box>

                                    <Box sx={{ marginBottom: "18px" }}>
                                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                            {t("currency")}
                                        </InputLabel>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    fullWidth
                                                    {...register("currency", {
                                                        required: t("isRequired"),
                                                    })}
                                                >
                                                    {
                                                        currencies.map((curr) => {
                                                            return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            )}
                                            rules={{ required: t("required") }}

                                        />
                                    </Box>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{ ml: "6px", mr: "6px", textAlign: "center" }}
                                        >
                                            {t("edit")}
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Container>
                        </AdminLayout>
                    </> : <>
                        <Loading />
                    </>
            }

        </>
    );
}
