import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

function JobCard({ Jobs }) {
    const { t } = useTranslation();
    const lang = Cookies.get("i18next") || "en";

    return (
        <Card 
            sx={{
                display: "flex", // يجعل المحتوى أفقيًا
                flexDirection: "row", // ترتيب المحتوى أفقيًا
                alignItems: "center", // محاذاة رأسية
                gap: 2, // مسافة بين الصورة والمحتوى
                maWidth: "100%", // عرض الكارد ليأخذ المساحة المتاحة
                margin: "10px auto", // مسافة حول الكارد
                padding: 2,
                boxShadow: 3, // إضافة ظل خفيف
            }}
        >
            {/* صورة الوظيفة */}
            <CardMedia
                component="img"
                sx={{
                    width: 250, // عرض ثابت للصورة
                    height: 200, // ارتفاع ثابت للصورة
                    borderRadius: "8px", // زوايا دائرية
                    objectFit: "cover" // ملء الصورة بشكل مناسب
                }}
                image={Jobs.image ? `${process.env.REACT_APP_API_KEY}images/${Jobs.image}` : "/logo.png"}
                alt={Jobs.titleArabic || t("Default Image")}
            />

            {/* محتوى الوظيفة */}
            <CardContent sx={{ flex: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                    {lang==="ar"?
                    <>{Jobs.titleArabic}</>:<>{Jobs.titleEnglish}</>}
                    
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`${t("Advertiser Name")}: ${Jobs.advertiserName}`}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`${t("country")}: ${t(Jobs.country)}`}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`${t("Email")}: ${Jobs.email}`}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`${t("phone")}: ${Jobs.phoneNumber}`}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    {`${t("description")}: ${lang==="ar"?Jobs.descriptionArabic:Jobs.descriptionEnglish}`}
                </Typography>
            </CardContent>
        </Card>
    );
}

JobCard.propTypes = {
    Jobs: PropTypes.shape({
        image: PropTypes.string,
        titleArabic: PropTypes.string,
        advertiserName: PropTypes.string,
        country: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        descriptionArabic: PropTypes.string,
        descriptionEnglish: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
    }).isRequired,
};

export default JobCard;
