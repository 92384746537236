import {
    Box,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import currencies from "../../data/currencies";

export default function AddLecture({ handleClose }) {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const { teacher } = useSelector((state) => state.teacher);
    const [file, setFile] = useState();


    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: "",
            price: "",
            currency: "",
            image: ""
        },
    });


    function handleFile(e) {
        setFile(e.target.files[0]);
        console.log(file);

    }
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";

    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();
        formData.append("image", file);
        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "image" && key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }
        console.log([...formData]);

        try {
            await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/create-lecture`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("A new lecture has been created."), { variant: "success", autoHideDuration: 8000 });
            navigate("/teacher/lectures")
        } catch (err) {
            enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <>
            <Box sx={{ width: "500px", maxWidth: "100%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleAr")}
                        </InputLabel>
                        <Controller
                            name="titleArabic"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleArabic", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleArabic?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleEn")}
                        </InputLabel>
                        <Controller
                            name="titleEnglish"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("titleEnglish", {
                                required: "title Address is required",
                            })}
                        />
                        {errors.titleEnglish?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("price")}
                        </InputLabel>
                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth />}
                            {...register("price", {
                                required: "price Address is required",
                            })}
                        />
                        {errors.price?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("currency")}
                        </InputLabel>
                        <Controller
                            name="currency"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    fullWidth
                                    {...register("currency", {
                                        required: t("isRequired"),
                                    })}
                                >
                                    {
                                        currencies.map((curr) => {
                                            return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                        })
                                    }
                                </Select>
                            )}
                            rules={{ required: t("required") }}

                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionArabic")}
                        </InputLabel>
                        <Controller
                            name="descriptionArabic"
                            control={control}
                            render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                            {...register("descriptionArabic", {
                                required: "description Address is required",
                            })}
                        />
                        {errors.descriptionArabic?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionEnglish")}
                        </InputLabel>
                        <Controller
                            name="descriptionEnglish"
                            control={control}
                            render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                            {...register("descriptionEnglish", {
                                required: "description Address is required",
                            })}
                        />
                        {errors.descriptionEnglish?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("chooseImage")}
                        </InputLabel>
                        <input type="file" onChange={handleFile} />

                    </Box>
                    <DialogActions>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{ ml: "6px", mr: "6px" }}
                        >
                            {t("save")}
                        </Button>
                        <Button onClick={handleClose} color="error">
                            {t("cancel")}
                        </Button>
                    </DialogActions>
                </form>

            </Box>
        </>
    );
}
