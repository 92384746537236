import {useQuery} from 'react-query'

async function getLectures(teacherId)
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/single-teacher/${teacherId}`)
    return response.json()
}

export const useAllLectures = (teacherId)=>
{
    return useQuery(['get-teacher-lectures',teacherId],()=>getLectures(teacherId))
}