import * as React from 'react';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useCurrency from "./../../../hooks/useCurrency";
import Cookies from "js-cookie";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Carousel from 'react-material-ui-carousel';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Test() {
  const [Tests, setAllTests] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const response = await instance.get("teacher/exams/status/accept-exams");
      const data = response.data.data || [];
      setAllTests([...data]);
    })();
  }, []);

  return (
    <Container sx={{ marginTop: "100px" }}>
      <h1 className='text-center text-5xl mb-6 mt-5' style={{ marginBottom: "60px" }}>{t("show Tests")}</h1>
      {
        !Tests.length ?
          <h1 className='text-2xl text-sky-500 mt-10 text-center'>
            {t("No test")}
          </h1>
          :
          <Carousel
            navButtonsAlwaysVisible={true}
            NextIcon={<ArrowForwardIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
            PrevIcon={<ArrowBackIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
          >
            {Tests.map((Test) => (
              <div key={Test.id}>
                <PackageCard test={Test} />
              </div>
            ))}
          </Carousel>
      }
    </Container>
  )
}

export default Test;

function PackageCard({ test }) {
  console.log(test);

  const MINUTE = 60;
  const navigate = useNavigate();
  const { currency } = useSelector(state => state.currency);
  const currencyObj = useCurrency(currency);
  const lang = Cookies.get("i18next") || "en";
  const { conversionRate } = useSelector(state => state.conversionRate);
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
  }
  return (
    <>
      <Card
        key={test.id}
        sx={{
          margin: "0rem auto 2rem",
          padding: "1rem",
          maxWidth: 600,
          maxHeight: 1000,
          boxShadow: 3,
        }}
      >
        {/* صورة الباقة */}
        <CardMedia
          component="img"
          height="140"
          image={test.image ? `${process.env.REACT_APP_API_KEY}images/${test.image}` : "/logo.png"}
          alt="package img"
          sx={{ filter: "brightness(50%)", height: "140px" }}
        />

        {/* العنوان */}
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: "yellow",
            fontWeight: "bold",
            marginTop: "0px",
            textShadow: "1px 1px 4px black",
          }}
        >
            {`${t("subject")} : `}

          {lang === "ar" ?
            <>{test.subject.titleAR}</>
            :
            <>{test.subject.titleEN}</>
          }
        </Typography>

        {/* تفاصيل الباقة */}
        <CardContent>
          <Typography variant="body2" sx={{ marginBottom: 1, display: "flex", justifyContent: "space-around" }}>
            <Typography variant="body2" sx={{ marginBottom: 1, textAlign: "center" }}>
          <Typography variant="body2" sx={{ marginBottom: 1,textAlign:"center",color:"#2ecc71"}}>{t("price")}</Typography>
          <Typography variant="body2" sx={{ marginBottom: 1,textAlign:"center",color:"#2ecc71" }} >{test.price}{t(test.currency)}</Typography>

            </Typography>

          </Typography>

          {/* حوار المعلم */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate(`/request-test/${test.teacher.id}/${test.id}`)}
            sx={{ marginBottom: "1rem" }}
          >
            {t("Request Test")}
          </Button>
        </CardContent>
      </Card>
    </>
  );

}
