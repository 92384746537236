import {
    Box,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loading from "../Loading";
import axios from "axios";
import currencies from "../../data/currencies";
import { useNavigate } from "react-router-dom";

export default function AddExam({ handleClose }) {
    const { t } = useTranslation();
    const { teacher } = useSelector((state) => state.teacher);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [subjects, setSubjects] = useState([])
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const getSubjects = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_KEY}api/v1/subject/allSubjects`);
            setSubjects(response.data.data);
            setLoading(true)
        };
        getSubjects()
    }, []);

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            price: "",
            subject: "",
            currency: ""
        },
    });


    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();

        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/create-exam`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("A new Exam has been created."), { variant: "success", autoHideDuration: 8000 });
        } catch (err) {
            console.log(err);
            enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <>
            {isLoading ?
                <>
                    <Box sx={{ width: "500px", maxWidth: "100%" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ marginBottom: "18px" }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Price of Test")}
                                </InputLabel>
                                <Controller
                                    name="price"
                                    control={control}
                                    render={({ field }) => <TextField type="number" {...field} fullWidth />}
                                    {...register("price", {
                                        required: "price is required",
                                    })}
                                />
                                {errors.price?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ marginBottom: "18px" }}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                        {t("subject")}
                                    </InputLabel>
                                    <Select
                                        labelId="subject"
                                        label={t("subject")}
                                        defaultValue="" // قيمة افتراضية
                                        {...register("subject", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {lang === "ar"
                                            ? subjects.map((subject, index) => (
                                                <MenuItem key={index} value={subject.id}>{t(subject.titleAR)}</MenuItem>
                                            ))
                                            : subjects.map((subject, index) => (
                                                <MenuItem key={index} value={subject.id}>{t(subject.titleEN)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginBottom: "18px" }}>
                                <FormControl fullWidth margin="dense">
                                    <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                        {t("currency")}
                                    </InputLabel>
                                    <Select
                                        labelId="currency"
                                        label={t("currency")}
                                        defaultValue="" // قيمة افتراضية
                                        {...register("currency", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {
                                            currencies.map((curr) => {
                                                return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ ml: "6px", mr: "6px" }}
                                >
                                    {t("save")}
                                </Button>
                            </DialogActions>
                        </form>

                    </Box>
                </>
                :
                <>
                    <Loading />
                </>}

        </>
    );
}
