import {useQuery} from 'react-query'

async function getStudentsOnline()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/students/isonline`)
    return response.json()
}

export const useStudentsOnline = ()=>
{
    return useQuery('get-students-online',getStudentsOnline)
}