import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import { useMyPendingLectures } from "../../hooks/useMyPendingLectures";

export default function LecturesCategoriesPendingByAdmin() {
    const { t } = useTranslation();

    const columns = [
        { id: "image", label: t("Photo"), minWidth: 150 },
        { id: "title", label: t("title"), minWidth: 150 },
        { id: "description", label: t("description"), minWidth: 150 },
        { id: "actions", label: t("actions"), minWidth: 150 },
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const { data, isLoading } = useMyPendingLectures();

    const [categories, setCategoires] = useState([]);

    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const lang = Cookies.get("i18next") || "en";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    // Added by Abdelwahab
    const handleAccept = async (id) => {
        closeSnackbar();
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/status/accept-lecture/${id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );

            enqueueSnackbar(t("Activation process was successful."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("Activation failed."), { variant: "error", autoHideDuration: 8000 });
        }
    };
    const rejectLecture = async (id) => {
        closeSnackbar();
        try {
            const res = await fetch(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/delete-lecture/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }
            );

            enqueueSnackbar(t("The lecture offer has been declined."), { variant: "success", autoHideDuration: 8000 });
            filterTeachers(id)
        } catch (err) {
            enqueueSnackbar(t("An error occurred while declining the lecture offer."), { variant: "error", autoHideDuration: 8000 });
        }
    };
    useEffect(() => {
        if (data?.data) {
            setCategoires(data.data);
        }
    }, [data]);

    function filterTeachers(id) {
        const filteredTeachers = categories.filter(
            (teacher) => teacher.id.toString() !== id.toString()
        );
        setCategoires(filteredTeachers);
    }
    console.log(categories);

    return (
        <Box>
            {!isLoading ? (
                <Paper sx={{ width: "100%", padding: "20px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={"center"}
                                        style={{ top: 57, minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {categories
                                    ?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" key={row.id + "demj"}>
                                                <TableCell align="center">                  <img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"100px"} align="center" /></TableCell>
                                                {
                                                    lang === "ar" ?
                                                        <>
                                                            <TableCell align="center">{row.titleArabic}</TableCell>
                                                            <TableCell align="center">{row.descriptionArabic}</TableCell>

                                                        </>
                                                        :
                                                        <>
                                                            <TableCell align="center">{row.titleEnglish}</TableCell>
                                                            <TableCell align="center">{row.descriptionEnglish}</TableCell>
                                                        </>
                                                }
                                                <TableCell align="center">

                                                    <Button color="success">
                                                        <DoneIcon onClick={() => handleAccept(row.id)} />
                                                    </Button>
                                                    <Button
                                                        color="error"
                                                        onClick={() => rejectLecture(row.id)}
                                                    >
                                                        <ClearIcon />
                                                    </Button>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={categories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : (
                <Loading />
            )}
        </Box>
    );
}
