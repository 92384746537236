import {useQuery} from 'react-query'

async function getPendingLectures()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/lectures/status/pending-lectures`
    )
    return response.json()
}

export const useMyPendingLectures = ()=>
{
    return useQuery('get-pending-lectures',()=>getPendingLectures())
}