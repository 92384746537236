import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Cookies from "js-cookie";
import { Navigate, Route, Routes } from "react-router-dom";
import TeacherAbout from "./pages/teacher/TeacherAbout";
import TeacherPhoto from "./pages/teacher/TeacherPhoto";
import AdminHome from "./pages/admin/AdminHome";
import Subjects from "./pages/admin/Subjects";
import AdditionalInformation from "./pages/teacher/AdditionalInformation";
import TeacherSubjects from "./pages/teacher/TeacherSubjects";
import TeacherResume from "./pages/teacher/TeacherResume";
import InsertCurriculums from "./pages/admin/InsertCurriculums";
import TeacherAvailability from "./pages/teacher/TeacherAvailability";
import TeacherDescription from "./pages/teacher/TeacherDescription";
import TeacherVideo from "./pages/teacher/TeacherVideo";
import Login from "./pages/auth/Login";
import StudentFirstStep from "./pages/auth/registerStudent/StudentFirstStep";
import StudentSecondStep from "./pages/auth/registerStudent/StudentSecondStep";
import StudentThirdStep from "./pages/auth/registerStudent/StudentThirdStep";
import StudentFouthStep from "./pages/auth/registerStudent/StudentFouthStep";
import TeacherFirstStep from "./pages/auth/registerTeacher/TeacherFirstStep";
import TeacherSecondStep from "./pages/auth/registerTeacher/TeacherSecondStep";
import TeacherThirdStep from "./pages/auth/registerTeacher/TeacherThirdStep";
import SingleTeacher from "./pages/client/SingleTeacher";
import SearchTeachers from "./pages/client/SearchTeachers";
import StudentProfile from "./pages/student/StudentProfile";
import StudentSettings from "./pages/student/StudentSettings";
import AdminLogin from "./pages/admin/AdminLogin";
import TeachersApprove from "./pages/admin/TeachersApprove";
import HomeParent from "./pages/parent/HomeParent";
import Home from "./pages/client/Home";
import { useSelector, useDispatch } from "react-redux";
import Landing from "./pages/client/Landing";
import SearchFilterTeacher from "./pages/client/SearchFilterTeacher";
import ParentRegister from "./pages/parent/ParentRegister";
import AdminLevels from "./pages/admin/AdminLevels";
import AdminClasses from "./pages/admin/AdminClasses";
import AdminCurriculums from "./pages/admin/AdminCurriculums";
import AdminSubjectCategories from "./pages/admin/AdminSubjectCategories";
import AdminParentStudent from "./pages/admin/AdminParentStudent";
import StudentMessages from "./pages/student/StudentMessages";
import StudentLessons from "./pages/student/StudentLessons";
import TeacherMessages from "./pages/teacher/TeacherMessages";
import StudentPhoto from "./pages/student/StudentPhoto";
import AboutUs from "./pages/client/AboutUs";
import Privacy from "./pages/client/Privacy";
import Terms from "./pages/client/Terms";
import StudentCredit from "./pages/student/StudentCredit";
import AdminBookedLessons from "./pages/admin/AdminBookedLessons";
import AdminPayments from "./pages/admin/AdminPayments";
import AdminTeachers from "./pages/admin/AdminTeachers";
import DetailsBook from "./pages/student/Book/DetailsBook";
import PayNow from "./pages/student/PayNow";
import SuccessCharge from "./pages/student/SuccessCharge";
import FailCharge from "./pages/student/FailCharge";
import StudentPaymentHistory from "./pages/student/StudentPaymentHistory";
import SuccessPayment from "./pages/student/SuccessPayment";
import FailPayment from "./pages/student/FailPayment";
import AdminStduents from "./pages/admin/AdminStduents";
import AdminStudentHistory from "./pages/admin/AdminStudentHistory";
import AdminTeacherDues from "./pages/admin/AdminTeacherDues";
import TeacherLessons from "./pages/teacher/TeacherLessons";
import TeacherNotifications from "./pages/teacher/TeacherNotifications";
import TeacherCredit from "./pages/teacher/TeacherCredit";
import ParentStudent from "./pages/parent/ParentStudent";
import { changeCurrency } from "./redux/currency";
import AdminSingleTeacher from "./pages/admin/AdminSingleTeacher";
import MapBrowser from "./pages/client/MapBrowser";
import AdminSocialMedia from "./pages/admin/AdminSocialMedia";
import AdminAddProfit from "./pages/admin/AdminAddProfit";
import ForgetPasswordFirstStep from "./pages/auth/forgetPassword/ForgetPassFirstStep";
import AdminMessages from "./pages/admin/AdminMessages";
import SingleBookedLesson from "./pages/admin/SingleBookedLesson";
import AdminHistory from "./pages/admin/AdminHistory";
import TeacherStudents from "./pages/teacher/TeacherStudents";
import StudentTeachers from "./pages/student/StudentTeachers";
import AdminNewStudent from "./pages/admin/AdminNewStudent";
import AdminNewTeacher from "./pages/admin/AdminNewTeacher";
import AdminEditTeacher from "./pages/admin/AdminEditTeacher";
import AdminEditStudent from "./pages/admin/AdminEditStudent";
import AdminCheckoutRequests from "./pages/admin/AdminCheckoutRequests";
import AdminEditTeacherPhoto from "./pages/admin/AdminEditTeacherPhoto";
import AdminEditTeacherAdditionalInfo from "./pages/admin/AdminEditTeacherAdditionalInfo";
import AdminEditTeacherAvailability from "./pages/admin/AdminEditTeacherAvailability";
import AdminEditTeacherDescription from "./pages/admin/AdminEditTeacherDescription";
import AdminEditTeacherResume from "./pages/admin/AdminEditTeacherResume";
import AdminEditTeacherSubjects from "./pages/admin/AdminEditTeacherSubjects";
import AdminEditTeacherVideo from "./pages/admin/AdminEditTeacherVideo";
import StudentFinancialRecords from "./pages/student/StudentFinancialRecords";
import ForgetPassSecondStep from "./pages/auth/forgetPassword/ForgetPassSecondStep";
import ForgetPassThirdStep from "./pages/auth/forgetPassword/ForgetPassThirdStep";
import AdminSignup from "./pages/admin/AdminSignup";
import AdminRates from "./pages/admin/AdminRates";
import AddPackage from './pages/teacher/AddPackage';
import AdminPackages from "./pages/admin/AdminPackages";
import AllSpecialties from "./pages/client/AllSpecialties";
import TeachersCountry from "./pages/client/TeachersCountry";
import PackageInfo from "./pages/student/PackageInfo";
import AdminMail from "./pages/admin/AdminMail";
import StudentPackage from "./pages/student/StudentPackage";
import PendingPackages from "./pages/admin/PendingPackages";
import MailForm from "./pages/admin/MailForm";
import TeacherPackages from "./pages/teacher/TeacherPackages";
import Careers from "./components/client/careers/Careers";
import Guest from "./components/client/guest/Guest";
import AdminJobs from "./pages/admin/AdminJobs";
import PendingJobs from "./pages/admin/PendingJobs";
import PendingAdvertisement from "./pages/admin/PendingAdvertisement";
import AdminAdvertisement from "./pages/admin/AdminAdvertisement";
import AdvertisementDetailes from "./components/client/home/AdvertisementDetailes";
import MyPackages from "./pages/teacher/MyPackages";
import EditPackage from "./pages/teacher/EditPackage";
import AddResources from "./pages/teacher/AddResources";
import Resources from "./pages/teacher/Resources";
import TeacherLectures from "./pages/teacher/TeacherLectures";
import TeacherQuestion from "./pages/teacher/TeacherQuestion";
import TeacherAnswer from "./pages/teacher/TeacherAnswer";
import TeacherExam from "./pages/teacher/TeacherExam";
import TeacherDiscount from "./pages/teacher/TeacherDiscount";
import TeacherSetting from "./pages/teacher/TeacherSetting";
import Resource from "./pages/admin/Resource";
import EditResource from "./components/teacher/EditResource";
import EditLecture from "./components/teacher/EditLecture";
import EditQuestion from "./components/teacher/EditQuestion";
import EditAnswer from "./components/teacher/EditAnswer";
import EditExam from "./components/teacher/EditExam";
import EditDiscount from "./components/teacher/EditDiscount";
import Advertisement from "./components/client/guest/Advertisement";
import JobDoc from "./components/client/guest/Job";
import SinglePackage from "./pages/client/SinglePackage";
import SingleResource from "./pages/client/SingleResource";
import SingleDiscount from "./pages/client/SingleDiscount";
import SingleLecture from "./pages/client/singleLecture";
import DiscountInfo from "./pages/student/DiscountInfo";
import ResourceInfo from "./pages/student/ResourceInfo";
import AdminStduentOnline from "./pages/admin/AdminStudentOnline";
import AdminTeachersOnline from "./pages/admin/AdminTeachersOnline";
import AdminLectures from "./pages/admin/AdminLectures";
import EditLectureByAdmin from "./components/admin/EditLectureByAdmin.jsx";
import AdminQuestions from "./pages/admin/AdminQuestion.jsx";
import EditQuestionByAdmin from "./components/admin/EditQuestionByAdmin.jsx";
import AdminDiscounts from "./pages/admin/AdminDiscounts.jsx";
import EditDiscountByAdmin from "./components/admin/EditDiscountByAdmin.jsx";
import AdminExams from "./pages/admin/AdminExmas.jsx";
import EditExamByAdmin from "./components/admin/EditExamByAdmin.jsx";
import LectureInfo from "./pages/student/LectureInfo.jsx";
import TestInfo from "./pages/student/TestInfo.jsx";
import StudentLecture from "./pages/student/StudentLecture.jsx";
import StudentDiscount from "./pages/student/StudentDiscount.jsx";
import StudentRecource from "./pages/student/StudentResource.jsx";
import StudentQuestion from "./pages/student/StudentQuestion.jsx";
import StudentExam from "./pages/student/StudentExam.jsx";
import StudentNotifications from "./pages/student/StudentNotifications.jsx";
import StudentNotify from "./pages/student/StudentNotify.jsx";


const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#005B8E",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFC93C",
      contrastText: "#ffffff",
    },
    Gray: {
      main: "#F6F6F6",
      contrastText: "#6D6D6D",
    },
    Blue: {
      main: "#ffffff33",
      contrastText: "#ffffff",
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  useEffect(() => {
    const lang = Cookies.get("i18next") || "en";
    if (lang === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, []);

  const { admin } = useSelector((state) => state.admin);
  const { teacher } = useSelector((state) => state.teacher);
  const { parent } = useSelector((state) => state.parent);
  const { student } = useSelector((state) => state.student);
  const { currency } = useSelector((state) => state.currency);

  useEffect(() => {
    async function getCurrency() {
      const response = await fetch(`https://ipapi.co/json/`);
      const data = await response.json();
      dispatch(changeCurrency({ currency: data?.currency }));
    }
    if (!currency) {
      getCurrency();
    }
  }, [currency, dispatch]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routes>
            {/** client pages */}
            <Route path="/" element={<Home />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/Guest" element={<Guest />} />
            <Route path="/Guest/advertisement/:email" element={<Advertisement />} />
            <Route path="/Guest/job/:email" element={<JobDoc />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="teachers/search" element={<SearchTeachers />} />
            <Route path="advert/:id" element={<AdvertisementDetailes />} />
            <Route path="teacher/:id/" element={<SingleTeacher />} />
            <Route path="teacher/:id/:packageId" element={<SinglePackage />} />
            <Route path="discount/:id/:discountId" element={<SingleDiscount />} />
            <Route path="resource/:id/:resourceId" element={<SingleResource />} />
            <Route path="lecture/:id/:lectureId" element={<SingleLecture />} />

            <Route
              path="/filter/:subjectId"
              element={<SearchFilterTeacher />}
            />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="PrivacyPolicy" element={<Privacy />} />
            <Route path="TermsAndConditions" element={<Terms />} />
            <Route path="/teachers/specialties" element={<AllSpecialties />} />
            <Route path="/teachers/country/:countryCode" element={<TeachersCountry
            />} />
            <Route
              path="map-browser"
              element={student ? <MapBrowser /> : <Navigate to="/" />}
            />

            {/** login page */}
            <Route path="login" element={<Login />} />

            {/** student auth */}
            <Route
              path="studentregister/step1"
              element={<StudentFirstStep />}
            />
            <Route
              path="studentregister/step2"
              element={<StudentSecondStep />}
            />
            <Route
              path="studentregister/step3"
              element={<StudentThirdStep />}
            />
            <Route
              path="studentregister/step4"
              element={<StudentFouthStep />}
            />

            {/** teacher auth */}
            <Route
              path="teacherRegister/step1"
              element={<TeacherFirstStep />}
            />
            <Route
              path="teacherRegister/step2"
              element={<TeacherSecondStep />}
            />
            <Route
              path="teacherRegister/step3"
              element={<TeacherThirdStep />}
            />
            <Route
              path="forgetPassword/step1"
              element={<ForgetPasswordFirstStep />}
            />
            <Route
              path="forgetPassword/step2"
              element={<ForgetPassSecondStep />}
            />
            <Route
              path="forgetPassword/step3"
              element={<ForgetPassThirdStep />}
            />
            {/** student pages */}
            <Route
              path="student/profile"
              element={student ? <StudentProfile /> : <Navigate to="/login" />}
            />
            <Route
              path="student/notify"
              element={student ? <StudentNotify /> : <Navigate to="/login" />}
            />
            <Route
              path="student/settings"
              element={student ? <StudentSettings /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/messages"
              element={student ? <StudentMessages /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/lessons"
              element={student ? <StudentLessons /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/package"
              element={student ? <StudentPackage /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/lecture"
              element={student ? <StudentLecture /> : <Navigate to="/login" />}
            />
           
            <Route
              path="/student/questions"
              element={student ? <StudentQuestion /> : <Navigate to="/login" />}
            />
             {/* work */}
            <Route
              path="/student/exam"
              element={student ? <StudentExam /> : <Navigate to="/login" />}
            />
             {/* work */}
            <Route
              path="/student/resource"
              element={student ? <StudentRecource /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/discount"
              element={student ? <StudentDiscount /> : <Navigate to="/login" />}
            />
            
            <Route
              path="/student/profile_photo"
              element={student ? <StudentPhoto /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/credit"
              element={student ? <StudentCredit /> : <Navigate to="/login" />}
            />
            <Route
              path="/book/:teacherId"
              element={student ? <DetailsBook /> : <Navigate to="/login" />}
            />
            <Route
              path="/request-package/:teacherId/:packageId"
              element={student ? <PackageInfo /> : <Navigate to="/login" />}
            />
            
            <Route
              path="/request-discount/:teacherId/:discountId"
              element={student ? <DiscountInfo /> : <Navigate to="/login" />}
            />
              <Route
              path="/request-lecture/:teacherId/:lectureId"
              element={student ? <LectureInfo /> : <Navigate to="/login" />}
            />
            <Route
              path="/request-test/:teacherId/:testId"
              element={student ? <TestInfo /> : <Navigate to="/login" />}
            />
            <Route
              path="/request-resource/:teacherId/:resourceId"
              element={student ? <ResourceInfo /> : <Navigate to="/login" />}
            />
            {/*  */}
            <Route
              path="/pay-now"
              element={student ? <PayNow /> : <Navigate to="/login" />}
            />
            <Route
              path="/student/payment-history"
              element={
                student ? <StudentPaymentHistory /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/student/financial-records"
              element={
                student ? <StudentFinancialRecords /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/student/teachers"
              element={student ? <StudentTeachers /> : <Navigate to="/login" />}
            />
            <Route
              path="student/notifications"
              element={
                student ? <StudentNotifications /> : <Navigate to="/login" />
              }
            />
            {/** teacher pages */}
            <Route
              path="teacher/about"
              element={teacher ? <TeacherAbout /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/photo"
              element={teacher ? <TeacherPhoto /> : <Navigate to="/login" />}
            />
            {/* now */}
            <Route
              path="teacher/package"
              element={teacher ? <MyPackages /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/add-package"
              element={teacher ? <AddPackage /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-package/:id"
              element={teacher ? <EditPackage /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/AddResources"
              element={teacher ? <AddResources /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-resource/:id"
              element={teacher ? <EditResource /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/resources"
              element={teacher ? <Resources /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-lecture/:id"
              element={teacher ? <EditLecture /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/lectures"
              element={teacher ? <TeacherLectures /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/questions"
              element={teacher ? <TeacherQuestion /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-questions/:id"
              element={teacher ? <EditQuestion /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/answers"
              element={teacher ? <TeacherAnswer /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-answer/:id"
              element={teacher ? <EditAnswer /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/exams"
              element={teacher ? <TeacherExam /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-exam/:id"
              element={teacher ? <EditExam /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/discounts"
              element={teacher ? <TeacherDiscount /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/edit-discount/:id"
              element={teacher ? <EditDiscount /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/setting"
              element={teacher ? <TeacherSetting /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/additionalInformation"
              element={
                teacher ? <AdditionalInformation /> : <Navigate to="/login" />
              }
            />
            <Route
              path="teacher/subjects"
              element={teacher ? <TeacherSubjects /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/resume"
              element={teacher ? <TeacherResume /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/availability"
              element={
                teacher ? <TeacherAvailability /> : <Navigate to="/login" />
              }
            />
            <Route
              path="teacher/description"
              element={
                teacher ? <TeacherDescription /> : <Navigate to="/login" />
              }
            />
            <Route
              path="teacher/video"
              element={teacher ? <TeacherVideo /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/messages"
              element={teacher ? <TeacherMessages /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/lessons"
              element={teacher ? <TeacherLessons /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/notifications"
              element={
                teacher ? <TeacherNotifications /> : <Navigate to="/login" />
              }
            />
            <Route
              path="teacher/credit"
              element={teacher ? <TeacherCredit /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/students"
              element={teacher ? <TeacherStudents /> : <Navigate to="/login" />}
            />
            <Route
              path="teacher/studentPackage/:studentId"
              element={teacher ? <TeacherPackages /> : <Navigate to="/login" />}
            />
            {/** admin pages */}
            <Route
              path="admin/login"
              element={!admin ? <AdminLogin /> : <Navigate to="/admin" />}
            />
            <Route
              path="admin"
              element={admin ? <AdminHome /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/signup"
              element={admin ? <AdminSignup /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/levels"
              element={admin ? <AdminLevels /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/years"
              element={
                admin ? <AdminClasses /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/curriculums"
              element={
                admin ? <AdminCurriculums /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/subjects"
              element={admin ? <Subjects /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/resource"
              element={admin ? <Resource /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/categories"
              element={
                admin ? (
                  <AdminSubjectCategories />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/lectures"
              element={
                admin ? (
                  <AdminLectures />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit-lecture/:id"
              element={admin ? <EditLectureByAdmin /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/questions"
              element={
                admin ? (
                  <AdminQuestions />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit-questions/:id"
              element={admin ? <EditQuestionByAdmin /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/exams"
              element={
                admin ? (
                  <AdminExams />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit-exams/:id"
              element={admin ? <EditExamByAdmin /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/discounts"
              element={
                admin ? (
                  <AdminDiscounts />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit-discount/:id"
              element={admin ? <EditDiscountByAdmin /> : <Navigate to="/admin/login" />}
            />
            <Route
              path="admin/Curriculums_insert"
              element={
                admin ? <InsertCurriculums /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/teachers_approve"
              element={
                admin ? <TeachersApprove /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/parent-student"
              element={
                admin ? <AdminParentStudent /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/rate"
              element={
                admin ? <AdminRates /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/packages"
              element={
                admin ? <AdminPackages /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/pending-packages"
              element={
                admin ? <PendingPackages /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/jobs"
              element={
                admin ? <AdminJobs /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/pending-jobs"
              element={
                admin ? <PendingJobs /> : <Navigate to="/admin/login" />
              }
            />teachers_approve
            <Route
              path="admin/advertisements"
              element={
                admin ? <AdminAdvertisement /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/pending-advertisement"
              element={
                admin ? <PendingAdvertisement /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/send-mail"
              element={
                admin ? <AdminMail /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/mail-form/:email"
              element={
                admin ? <MailForm /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/booked-lessons"
              element={
                admin ? <AdminBookedLessons /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/payments"
              element={
                admin ? <AdminPayments /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/teachers"
              element={
                admin ? <AdminTeachers /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/teachers-online"
              element={
                admin ? <AdminTeachersOnline /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/students"
              element={
                admin ? <AdminStduents /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/students-online"
              element={
                admin ? <AdminStduentOnline /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/social-media"
              element={
                admin ? <AdminSocialMedia /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/add-profit"
              element={
                admin ? <AdminAddProfit /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/student/:id/payment"
              element={
                admin ? <AdminStudentHistory /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/teacher/:id/dues"
              element={
                admin ? <AdminTeacherDues /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/teacher/:teacherId"
              element={
                admin ? <AdminSingleTeacher /> : <Navigate to="/admin/login" />
              }
            />
            {/* Edited by Abdelwahab */}
            <Route
              path="admin/messages"
              element={
                admin ? <AdminMessages /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/history"
              element={
                admin ? <AdminHistory /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/booked-lesson/:bookedLessonId"
              element={
                admin ? <SingleBookedLesson /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/new/student"
              element={
                admin ? <AdminNewStudent /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/new/teacher"
              element={
                admin ? <AdminNewTeacher /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/edit/student/:studentId"
              element={
                admin ? <AdminEditStudent /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/edit/teacher/:teacherId"
              element={
                admin ? <AdminEditTeacher /> : <Navigate to="/admin/login" />
              }
            />
            <Route
              path="admin/edit/teacher/photo/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherPhoto />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/additionalInformation/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherAdditionalInfo />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/availability/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherAvailability />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/description/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherDescription />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/resume/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherResume />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/subjects/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherSubjects />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/edit/teacher/video/:teacherId"
              element={
                admin ? (
                  <AdminEditTeacherVideo />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            <Route
              path="admin/checkout-requests"
              element={
                admin ? (
                  <AdminCheckoutRequests />
                ) : (
                  <Navigate to="/admin/login" />
                )
              }
            />
            {/** parent pages */}
            <Route path="parent/register" element={<ParentRegister />} />
            <Route
              path="parent"
              element={parent ? <HomeParent /> : <Navigate to="/" />}
            />
            <Route
              path="parent-dash/student/:id"
              element={parent ? <ParentStudent /> : <Navigate to="/" />}
            />

            {/** success and fail pages */}
            <Route path="/success-charge" element={<SuccessCharge />} />
            <Route path="/fail-charge" element={<FailCharge />} />
            <Route path="/success-payment" element={<SuccessPayment />} />
            <Route path="/fail-payment" element={<FailPayment />} />
            <Route />
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
