import React from 'react'
import Navbar from '../../Navbar'
import Footer from '../home/Footer'
import SelectGuest from './SelectGuest'
import LinksFooter from '../home/LinksFooter'

export default function Guest() {
    
    return (
        <Navbar>
            <SelectGuest />
            <LinksFooter />
            <Footer/>
        </Navbar>
    )
}
