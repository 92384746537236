import { Box, CardMedia, Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
export default function AboutLecture({ lectureData }) {
    console.log(lectureData);
    const { t } = useTranslation()
    const lang = Cookies.get("i18next") || "en";
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
      }
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <CardMedia
                component="img"
                height="140"
                image={lectureData.image ? `${process.env.REACT_APP_API_KEY}images/${lectureData.image}` : "/logo.png"}
                alt="package img"
                sx={{ filter: "brightness(50%)", height: "400px" }}
            />
            <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <Typography
                   variant="h4"
                    sx={{ marginBottom: "8px",marginTop:"8px", fontWeight: "700" }}
                >
                    {
                        lang==="ar"?
                        <>{lectureData.titleArabic}</>
                        :
                        <>{lectureData.titleEnglish}</>
                    }
                </Typography>
            <Typography
                    variant="h6"
                    
                    sx={{ marginBottom: "8px", fontWeight: "700",marginTop:"18px",color:"red"}}
                >
                    {lectureData.price} {t(lectureData.currency)}
                </Typography>
            </Box>

                <Typography
                    variant="body1"
                    sx={{ marginBottom: "15px", fontWeight: "400"}}
                >
                    {
                        lang==="ar"?
                        <>{lectureData.descriptionArabic}</>
                        :
                        <>{lectureData.descriptionEnglish}</>
                    }
                </Typography>

        </Paper>

    )
}
