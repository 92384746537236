import { useQuery } from "react-query";
import instance from "./../axios/instance";

async function getMyStudentPackages(studentId) {
  const res = await instance(`student/getPackages/${studentId}`);
  return res.data;
}
export const useMyStudentPackages = (studentId) => {
  return useQuery(["get-teacher-students-packages", studentId], () =>
    getMyStudentPackages(studentId)
  );
};
