import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import Loading from "../../components/Loading";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import { Delete, Edit, Visibility} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMyTeacherResources } from "../../hooks/useMyTeacherResources";
import ViewResource from "../../components/admin/ViewResource";


export default function Resources() {
  const lang = Cookies.get("i18next") || "en";
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { teacher } = useSelector((state) => state.teacher)
  const { data, isLoading } = useMyTeacherResources(teacher.id);
  const [resource, setResource] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.data) {
      setResource(data.data);
    }
  }, [data]);

  const columns = [
    { id: "ID", label: t("ID"), minWidth: 150 },
    { id: "Type of Resource", label: t("Type of Resource"), minWidth: 150 },
    { id: "price", label: t("price"), minWidth: 150 },
    { id: "status", label: t("status"), minWidth: 150 },
    { id: "view", label: t("view"), minWidth: 150 },
    { id: "update", label: t("update"), minWidth: 150 },
    { id: "delete", label: t("delete"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

   /** handle open dialog */
   const [open, setOpen] = React.useState(false);
 
   const handleClose = () => {
     setOpen(false);
   };
  async function editResource(id) {
    navigate(`/teacher/edit-resource/${id}`)
  }

  async function DeleteResource(id) {
    closeSnackbar();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/resources/delete-resource/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        enqueueSnackbar(t("The Resource has been not removed."), {
          variant: "error",
          autoHideDuration: 8000,
        });
      }else
      {
        enqueueSnackbar(t("The Resource has been removed."), {
          variant: "success",
          autoHideDuration: 8000,
        });
  
        filterTeachers(id);
      }

    } catch (error) {
      enqueueSnackbar(t("The Resource has been removed."), {
        variant: "error",
        autoHideDuration: 8000,
      });
    }
  }

  function filterTeachers(id) {
    const filteredTeachers = resource.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setResource(filteredTeachers);
  }

  return (
    <Navbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          {t("List of Advertisement")}
        </Typography>
      </Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {resource.length > 0 &&
                  resource
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id + "demj"}>
                              <TableCell align="center">
                                {t(row.id)}
                              </TableCell>
                          <TableCell align="center">{t(row.resource)}</TableCell>
                          <TableCell align="center">{row.price}</TableCell>
                          {row.isVerified ? <>
                            <TableCell align="center" >
                              {t("Available")}
                            </TableCell>
                          </> : <>
                            <TableCell align="center">
                              {t("Review")}
                            </TableCell>
                          </>}

                          <TableCell align="center">
                            <Button
                              color="warning"
                              onClick={() => setOpen(row.id)}
                            >
                              <Visibility />
                            </Button>
                         
                          <Dialog open={open === row.id} onClose={handleClose}>
                            <ViewResource
                              setResource={setResource}
                              resource={row}
                              handleClose={handleClose}
                            />
                          </Dialog>
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              color="info"
                              onClick={() => editResource(row.id)}
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              color="error"
                              onClick={() => DeleteResource(row.id)}
                            >
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={resource.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </Navbar>
  );
}