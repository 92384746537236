import { useQuery } from "react-query";

async function getMyTeacherPackages(teacherId) {
    
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/packages/mypackages/${teacherId}`)

    return response.json()
}
export const useMyTeacherPackages = (teacherId) => {
    return useQuery(["get-teacher-mypackages",teacherId], () =>
        getMyTeacherPackages(teacherId)
    );
};
