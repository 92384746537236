import React from 'react';
import StudentLayout from '../../components/student/StudentLayout'
import instance from '../../axios/instance';
import { useSelector } from 'react-redux';
import { Divider, styled } from '@mui/material';
import { t } from 'i18next';
import Loading from '../../components/Loading';
import PackageDetails from '../../components/reusableUi/PackageDetails';
import Cookies from "js-cookie";


const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

function StudentPackage() {
  const lang = Cookies.get("i18next") || "en";

  const { student } = useSelector(state => state.student);
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      const res = await instance(`student/getPackages/${student.id}`);
      setData(res.data);
    })();
  }, [student.id]);

  if (data === null) return <Loading />
  else if (!data.length) return <StudentLayout><h1 className='text-center text-2xl text-sky-500 mt-20'>{t("noPackage")}</h1></StudentLayout>
  else return (
    <StudentLayout>
      <Root>
        {
          data.map((data) => {
            return <>
              <section className='grid grid-cols-3 gap-4'>
                <div className='col-span-2'>
                  <h1 className='text-2xl'>{t("package")}: 
                  {lang === "ar" ?
                      <>{" "+data.titleAr}</>
                      :
                      <>{" "+data.titleEn}</>}
                  </h1>
                  <p className='truncate text-lg'>{t("description")}:
                    {lang === "ar" ?
                      <>{" "+data.descriptionAr}</>
                      :
                      <>{" "+data.descriptionEn}</>}
                  </p>
                </div>
                <PackageDetails data={data} teacherButton={true} />
              </section>
              <Divider ></Divider>
            </>
          })
        }
      </Root>
    </StudentLayout>
  )
}

export default StudentPackage;