import React, { useEffect, useState } from "react";
import { Container, Avatar, Rating } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { t } from "i18next";
import instance from "../../../axios/instance";

function Rates() {
  const [rates, setRates] = useState([]);

  useEffect(() => {
    const fetchRatesAndStudents = async () => {
      try {
        // الحصول على جميع التقييمات
        const response = await instance.get("rate/all-rates");
        const data = response.data.data || [];

        // الحصول على بيانات الطلاب المتعلقة بالتقييمات
        const studentPromises = data.map((rate) =>
          instance.get(`student/get/${rate.StudentId}`).then((res) => res.data.data)
        );
        const students = await Promise.all(studentPromises);

        // دمج بيانات التقييمات مع الطلاب
        const ratesWithStudents = data.map((rate, index) => ({
          ...rate,
          student: students[index],
        }));

        setRates(ratesWithStudents);
      } catch (error) {
        console.error("Error fetching rates or students:", error);
      }
    };

    fetchRatesAndStudents();
  }, []);

  return (
    <Container style={{ marginTop: "80px", marginBottom: "60px" }}>
      <h1 className="text-center text-5xl" style={{ marginBottom: "60px" }}>
        {t("View Rate")}
      </h1>
      <div className="slider-container">
        <Carousel 
          indicators={true} 
          animation="slide" 
          duration={500} 
          navButtonsAlwaysVisible={true}
        >
          {rates.map((rate) => (
            <div key={rate.id} className="bg-white p-4 rounded border-8 border-grey-200" style={{margin:"0 10rem"}}>
              <div dir="rtl">
                <img src="/quote.png" className="mb-4" width={50} alt="quote mark" />
                <Rating name="read-only" size="small" value={rate.rating} readOnly />
                <p className="my-4">{rate.comment}</p>
              </div>
              <div className="flex items-center">
                <Avatar
                  src={`${process.env.REACT_APP_API_KEY}images/${rate.student?.image}`}
                  alt={rate.student?.name || "Student"}
                />
                <span className="ms-2">{rate.student?.name || "Unknown Student"}</span>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </Container>
  );
}

export default Rates;
