import { useQuery } from "react-query";

async function getMyTeacherResources(teacherId) {
    
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/resources/all-resources/${teacherId}`)

    return response.json()
}
export const useMyTeacherResources = (teacherId) => {
    return useQuery(["get-teacher-all-resources",teacherId], () =>
        getMyTeacherResources(teacherId)
    );
};
