import {useQuery} from 'react-query'

async function getQuestions(teacherId)
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/single-teacher/${teacherId}`)
    
    return response.json()
}

export const useAllQuestions = (teacherId)=>
{
    return useQuery(['get-teacher-question',teacherId],()=>getQuestions(teacherId))
}