import React, { useState } from "react";
import AdminLayout from "../../components/admin/AdminLayout";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useWaitingJobs } from "../../hooks/useWaitingJobs";
import Loading from "../../components/Loading";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Cookies from 'js-cookie';

export default function PendingJobs() {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { data, isLoading } = useWaitingJobs();
  const [teachers, setTeachers] = useState([]);
  useEffect(() => {
    if (data?.data) {
      setTeachers(data.data);
    }
  }, [data]);
  console.log(data);
    const lang = Cookies.get("i18next") || "en";
  
  const columns = [
    { id: "Image Job", label: t("Image Job"), minWidth: 150 },
    { id: "Advertiser Name", label: t("Advertiser Name"), minWidth: 150 },
    { id: "Title", label: t("title"), minWidth: 150 },
    { id: "Country", label: t("country"), minWidth: 150 },
    { id: "description", label: t("description"), minWidth: 150 },
    { id: "phone", label: t("phone"), minWidth: 150 },
    { id: "Actions", label: t("actions"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

async function acceptTeacher(id) {
  closeSnackbar();  

  const formData = {
    status: "متاحة"
  };

  try {
    await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/job/${id}/status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), 
      }
    );
  
    enqueueSnackbar(t("Job offer accepted"), {
        variant: "success",
        autoHideDuration: 8000,
      });
      filterTeachers(id);

  } catch (error) {
    enqueueSnackbar(t("An error occurred while accepting the job offer."), {
      variant: "error",
      autoHideDuration: 8000,
    });
  }
  }

async function rejectTeacher(id) {
  closeSnackbar(); 

  try {
    await fetch(
      `${process.env.REACT_APP_API_KEY}api/v1/job/${id}`,
      {
        method: "DELETE",  
        headers: {
          "Content-Type": "application/json",  
        },
      }
    );
      enqueueSnackbar(t("Job offer declined"), {
        variant: "success",
        autoHideDuration: 8000,  
      });
      filterTeachers(id);
  } catch (error) {
    enqueueSnackbar(t("An error occurred while declining the job offer."), {
      variant: "error",  
      autoHideDuration: 8000,
    });
  }
  }

function filterTeachers(id) {
    const filteredTeachers = teachers.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setTeachers(filteredTeachers);
  }
  
  return (
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
          {t("List of Job")}
        </Typography>
      </Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {teachers.length > 0 &&
                  teachers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id + "demj"}>
                          <TableCell align="center">
                          <img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"100px"} />
                          </TableCell>
                          <TableCell align="center">{row.advertiserName
                          }</TableCell>
                          <TableCell align="center">
                            {lang==="ar"?
                            <>
                            {row.titleArabic || ""}
                            </>:
                            <>
                            {row.titleEnglish || ""}
                            </>}
                          </TableCell>
                          <TableCell align="center">
                            {t(row.country) || ""}
                          </TableCell>
                          <TableCell align="center">
                          {lang==="ar"?
                            <>
                            {row.descriptionArabic || ""}
                            </>:
                            <>
                            {row.descriptionEnglish || ""}
                            </>}
                          </TableCell>
                          <TableCell align="center">
                            {row.phoneNumber || ""}
                          </TableCell>
                          <TableCell align="center">
                            <Button color="success">
                              <DoneIcon onClick={() => acceptTeacher(row.id)} />
                            </Button>
                            <Button
                              color="error"
                              onClick={() => rejectTeacher(row.id)}
                            >
                              <ClearIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={teachers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </AdminLayout>
  );
}

