import React from 'react'
import { Button, FormControl } from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { t } from 'i18next';
import AdminLayout from '../../components/admin/AdminLayout';
import { useParams } from 'react-router-dom';
import instance from '../../axios/instance';
import { useSnackbar } from 'notistack';
import Cookies from "js-cookie";


function MailForm() {
  const { email } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const lang = Cookies.get("i18next") || "en";

  async function sendMail(data) {
    data.preventDefault();
    const value = data.target[0].value;
    try {
      const res = await instance.post(`/admin/admin-mail/${email}`, { message: value });
      const resMessage = res.data.msg;
      const message = lang === "en" ? resMessage.en : resMessage.ar;
      enqueueSnackbar(message, { variant: "success", autoHideDuration: "5000" });
      
    } catch {
      enqueueSnackbar(t("error"), { variant: "error", autoHideDuration: "5000" });
    }

  }
  return (
    <AdminLayout>
      <form onSubmit={sendMail}>
        <FormControl fullWidth margin="dense">
          <TextareaAutosize required fullWidth minRows={5} placeholder={t("mailPlaceholder")} />
        </FormControl>
        <Button variant="contained" type='submit' sx={{ width: "300px", marginTop: "20px" }} >{t("send")}</Button>
      </form>
    </AdminLayout>
  )
}

export default MailForm;
