import {useQuery} from 'react-query'

async function getAcceptDiscounts()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/status/accept-discounts`
    )
    return response.json()
}

export const useMyAcceptDiscounts = ()=>
{
    return useQuery('get-accept-discounts',()=>getAcceptDiscounts())
}