import { useQuery } from "react-query";

async function getMyTeacherDiscounts(teacherId) {
    
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/single-teacher/${teacherId}`)
    return response.json()
}
export const useMyTeacherDiscounts = (teacherId) => {
    return useQuery(["get-teacher-discount",teacherId], () =>
        getMyTeacherDiscounts(teacherId)
    );
};
