import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import Cookies from "js-cookie";

function PackageDetails({ data, teacherButton }) {  
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { currency } = useSelector(status => status.currency);
  const { conversionRate } = useSelector((state) => state.conversionRate);
  const image = data.image ? `${process.env.REACT_APP_API_KEY}images/${data.image}` : "/logo.png";
  const handleClose = () => {
    setOpen(false);
  };
  const lang = Cookies.get("i18next") || "en";
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
}
  return <React.Fragment>
    <Button variant="contained" size='small' sx={{height:"50px"}} onClick={() => setOpen(true)}>{t("details")}</Button>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{t("package")}</Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>{t("close")}</Button>
        </Toolbar>
      </AppBar>
      <DialogTitle>
        <div className='flex justify-center mt-5'>
          <img className='object-fill h-48 w-96 shadow' src={image} alt="package img" />
        </div>
        <h1 className='text-center text-xl mt-4'>
        {lang==="ar" ? 
            <>
              {`${t("package")}: ${data.titleAr}`}
            </> : 
            <>
            {`${t("package")}: ${data.titleEn}`}
            </>
            }
        </h1>
      </DialogTitle>
      <DialogContent>
        <p className='sm:truncate mb-8 text-lg'>
        {lang==="ar" ? 
            <>
          {t("description")}: {data.descriptionAr}
            </> : 
            <>
          {t("description")}: {data.descriptionEn}
            </>
            }
        </p>
        <div className="grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <Typography variant="h5">
            {lang==="ar" ? 
            <>
              {`${t("level")}: ${data.level.titleAR}`}
            </> : 
            <>
            {`${t("level")}: ${data.level.titleEN}`}
            </>
            }
          </Typography>
          <Typography variant="h5">
            {`${t("sharesCount")}: ${data.numberOfShares} ${t("shares")}`}
          </Typography>
          <Typography variant="h5">
            {`${t("sharesCountInWeek")}: ${data.sharesInWeek} ${t("shares")}`}
          </Typography>
          <Typography variant="h5">
            {`${t("sharePrice")}: ${(data.price * conversionRate).toFixed(2)}`}
          </Typography>
          <Typography variant="h5">
            {`${t("sharesTotal")}: ${(data.price * data.numberOfShares * conversionRate).toFixed(2)}`}
          </Typography>
          <Typography variant="h5">
            {`${t("currency")}: ${t(currency)}`}
          </Typography>
          <Typography gutterBottom variant="h5">
            {`${t("duration")}:`} {`${data.duration} ${t("hour")}`}
          </Typography>
          <Typography variant="h5">
            {t("startDate")}: {convertDate(data.startDate)}
          </Typography>
          <Typography variant="h5">
            {t("endDate")}: {convertDate(data.endDate)}
          </Typography>
          <Typography variant="h5">
            {t("packagePlace")}: {t(`${data.packageType}_place`)}
          </Typography>
          <Typography variant="h5">
            {t("registryDate")}: {data.createdAt.split("T")[0]}
          </Typography>
        </div>
      </DialogContent>
      {
        teacherButton ? <DialogActions sx={{ margin: "auto" }}>
          <Button variant='contained' sx={{ width: "400px" }} onClick={() => navigate("/teacher/" + data.teacherId)}>{t("teacher")}</Button>
        </DialogActions> : ""
      }

    </Dialog>
  </React.Fragment>
}

export default PackageDetails;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});