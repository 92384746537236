import { Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
function PackageSingleTeacher({ teacher }) {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const lang = Cookies.get("i18next") || "en";
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <Typography sx={{ fontSize: "22px", marginBottom: "18px" }}>
                {t('packages')}
            </Typography>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "center" }}>
                {teacher ? (
                    teacher.map((row, index) => (
                        <Card sx={{ Width: 400, padding: 4, background: "#eaeded", borderRadius: 5 }} key={row.id || index}>
                            <CardActionArea>
                                <Avatar
                                    src={`${process.env.REACT_APP_API_KEY}images/${row?.image}`}
                                    sx={{ width: "141px", height: "141px" }}
                                />
                                <CardContent className='text-center'>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {lang === "ar" ?
                                            <>
                                                {row.titleAr || t("No Title")}
                                            </>
                                            :
                                            <>
                                                {row.titleEn || t("No Title")}
                                            </>}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" style={{width:"100px",textOverflow: "ellipsis",whiteSpace: "nowrap",overflow: "hidden"}}>
                                        {lang === "ar" ?
                                            <>
                                                {row.descriptionAr || t("No Title")}
                                            </>
                                            :
                                            <>
                                                {row.descriptionEn || t("No Title")}
                                            </>}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ justifyContent: "center" }}>
                                <Button size="small" color="primary" onClick={() => navigate(`./${row.id}`)}>
                                    {t("View more")}
                                </Button>
                            </CardActions>
                        </Card>
                    ))
                ) : (
                    <Typography
                        sx={{
                            fontSize: "16px",
                            margin: "5px",
                            padding: "10px",
                            background: "#f8f9f9",
                            textAlign: "center",
                        }}
                    >
                        {t('There are no special Packages.')}
                    </Typography>
                )}
            </Box>
        </Paper>
    )
}

export default PackageSingleTeacher