import { Card, Button, CardContent, Typography, Rating, Avatar, CardActions, Chip } from "@mui/material";
import { t } from 'i18next';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function TeacherCard({ teacher }) {
  const flagCode = typeof teacher.country === "string" ? teacher.country.toLowerCase() : "notFound";
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (<Card key={teacher.id} sx={{ padding: "30px" }} dir='rtl'>
    <div className='flex justify-center'>
      <Avatar
        alt=""
        src={`${process.env.REACT_APP_API_KEY}images/${teacher.image}`}
        sx={{ width: 100, height: 100, border: "2px solid #fff", boxShadow: "2px 2px #005B8E" }}
      />
    </div>
    <CardContent className='border-b border-gray-300'>
      <Typography gutterBottom className='block'>
        {`${teacher.firstName} ${teacher.lastName}`}
      </Typography>
      <Rating name="read-only" size='small' value={teacher.rate} readOnly />
      <Typography sx={{ marginBottom: "10px" }}>
        <span className='px-1 text-xs bg-gray-200 rounded'>{t("Offline teaching")}</span>
        &nbsp;&nbsp;
        <span className='px-1 text-xs bg-sky-300/25 text-sky-500 rounded'>{t("Online teaching")}</span>
      </Typography>
      <Chip
        avatar={<Avatar alt="Cindy Baker" src={`/flags/${flagCode}.svg`} sx={{ width: 40, height: 40 }} />}
        label={`${teacher.city}`}
        variant="outlined"
        onClick={() => navigate(`/teachers/country/${teacher.country}`)}
      />
    </CardContent>
    <CardActions>
      <Button variant="contained" onClick={() => navigate(`/teacher/${teacher.id}`)} fullWidth>{t("contactMe")}</Button>
    </CardActions>
  </Card>
  )
}

export default TeacherCard;
