import { Paper, Typography, Box, InputLabel, TextField, Button } from "@mui/material"
import { useForm, Controller } from "react-hook-form"
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../components/admin/AdminLayout';
import cookies from "js-cookie";
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from "react";
import { t } from "i18next";

// Its new page to create new admin created by badran.
function AdminSignup() {
  const [loading, setLoading] = useState(false);
  const lang = cookies.get("i18next") || "en";
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { register, control, handleSubmit, getValues, formState: { errors } } = useForm();
  async function onSubmit(data) {
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name: data.adminName, email: data.email, password: data.password })
    });
    const resData = await response.json();
    const message = lang === "en" ? resData.message.english : resData.message.arabic;
    if (response.status !== 200) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        enqueueSnackbar(message, { variant: "error", autoHideDuration: "8000" });
      }, 2000);
    }
    else {
      setLoading(true);
      setTimeout(() => {
        enqueueSnackbar(message, { variant: "success", autoHideDuration: "8000" });
        navigate("/admin");
      }, 2000);
    }
  }
  return (
    <AdminLayout>
      <Paper sx={{ marginBottom: "40px", padding: "30px", width: "900px", maxWidth: "100%", marginTop: "20px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ fontSize: "28px", fontWeight: "600", marginBottom: "22px", textAlign: "center" }}>{t("newAdmin")}</Typography>
          <Box sx={{ marginBottom: "30px" }}>
            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>Name</InputLabel>
            <Controller
              name="adminName"
              control={control}
              render={({ field }) => <TextField type="text" {...field} fullWidth />}
              {...register("adminName", { required: "Name is required" })}
            />
            {errors.adminName?.type === 'required' && <Typography color="error" role="alert" sx={{ fontSize: "13px", marginTop: "6px" }}>this field is required</Typography>}
          </Box>
          <Box sx={{ marginBottom: "30px" }}>
            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>Email</InputLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <TextField type="email" {...field} fullWidth />}
              {...register("email", { required: "email Address is required" })}
            />
            {errors.email?.type === 'required' && <Typography color="error" role="alert" sx={{ fontSize: "13px", marginTop: "6px" }}>this field is required</Typography>}
          </Box>
          <Box sx={{ marginBottom: "30px" }}>
            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>Password</InputLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => <TextField type="password" {...field} fullWidth />}
              {...register("password", { required: "password Address is required" })}
            />
            {errors.password?.type === 'required' && <Typography color="error" role="alert" sx={{ fontSize: "13px", marginTop: "6px" }}>this field is required</Typography>}
          </Box>
          <Box sx={{ marginBottom: "30px" }}>
            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>Password</InputLabel>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => <TextField type="password" {...field} fullWidth />}
              {...register("confirmPassword", {
                required: "Field is required",
                validate: (value) => {
                  return value === getValues("password") || "This field no match password";
                }
              })}
            />
            {errors.confirmPassword && <Typography color="error" role="alert" sx={{ fontSize: "13px", marginTop: "6px" }}>{errors.confirmPassword?.message}</Typography>}
          </Box>
          <>
            {
              loading ?
                <LoadingButton loading fullWidth loadingPosition="start" variant="outlined">
                  Loading...
                </LoadingButton>
                :
                <Button variant='contained' fullWidth type='submit' sx={{ textTransform: "capitalize" }}>
                  SignUp
                </Button>
            }
          </>
        </form>
      </Paper>
    </AdminLayout>
  )
}

export default AdminSignup;
