import { Avatar, Box, CardMedia, Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from 'swiper';
import { styled } from '@mui/material/styles';
export default function AboutDiscount({ data }) {
    console.log(data);

    const { t } = useTranslation()
    const lang = Cookies.get("i18next") || "en";
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
      }
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <CardMedia
                component="img"
                height="140"
                image={data.image ? `${process.env.REACT_APP_API_KEY}images/${data.image}` : "/logo.png"}
                alt="package img"
                sx={{ filter: "brightness(50%)", height: "400px" }}
            />
                <Typography
                   variant="h4"
                    sx={{ marginBottom: "8px",marginTop:"8px", fontWeight: "700" }}
                >
                    {
                        lang==="ar"?
                        <>{data.titleArabic}</>
                        :
                        <>{data.titleEnglish}</>
                    }
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "15px", fontWeight: "400"}}
                >
                    {
                        lang==="ar"?
                        <>{data.descriptionArabic}</>
                        :
                        <>{data.descriptionEnglish}</>
                    }
                </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("Discount Type")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                  {t(data.discountType)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("Amount Before Discount")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                     {t(data.amountBeforeDiscount)}  
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("Amount After Discount")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {t(data.amountAfterDiscount)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("startDate")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {convertDate(data.discountStartDate)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("End Date")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#ec7063" }}
                >
                     {convertDate(data.discountEndDate)}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("terms And Conditions")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2" }}
                >
                    {
                        lang==="ar"?
                        <>{data.termsAndConditionsArabic}</>
                        :
                        <>{data.termsAndConditionsEnglish}</>
                    }
                </Typography>
            </Box>
        </Paper>

    )
}
