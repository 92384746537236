import React from "react";
import {
  CardMedia,
  Typography,
  Grid,
  Box,
  Paper,
  Button,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import instance from "../../../axios/instance";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdvertisementData = () => {
  const { t } = useTranslation();
  const [advertisement, setAdvertisement] = React.useState(null);
  const { id } = useParams();
    const lang = Cookies.get("i18next") || "en";

  React.useEffect(() => {
    (async () => {
      try {
        const response = await instance.get(`advertisement/${id}`);
        const data = response.data.data || null;
        setAdvertisement(data);
      } catch (error) {
        console.error("Error fetching advertisement:", error);
      }
    })();
  }, [id]);

  if (!advertisement) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 5 }}>
        {t("Loading...")}
      </Typography>
    );
  }
  console.log(advertisement);
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
}

  return (
    <Container sx={{ marginBottom: "40px" }}>
    <Grid container spacing={1}>
      <Grid item sm={12} md={12} lg={12}>
        <Paper sx={{ padding: "32px 24px" }}>
          <CardMedia
            component="img"
            height="140"
            image={
              advertisement.image
                ? `${process.env.REACT_APP_API_KEY}images/${advertisement.image}`
                : "/logo.png"
            }
            alt="package img"
            sx={{ filter: "brightness(50%)", height: "300px" }}
          />
          <Typography
            variant="h4"
            sx={{
              marginBottom: "17px",
              marginTop: "8px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {lang === "ar" ? (
              <>{advertisement.titleArabic}</>
            ) : (
              <>{advertisement.titleEnglish}</>
            )}
          </Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {/* {t("Field")} */}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {t("details")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t("Advertiser Name")}</TableCell>
                  <TableCell align="center" sx={{ color: "#5dade2" }}>
                    {advertisement.advertiserName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t("description")}</TableCell>
                  <TableCell align="center" sx={{ color: "#5dade2" }}>
                    {lang === "ar" ? (
                      <>{advertisement.descriptionArabic}</>
                    ) : (
                      <>{advertisement.descriptionEnglish}</>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t("Email")}</TableCell>
                  <TableCell align="center" sx={{ color: "#5dade2" }}>
                    {advertisement.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t("phone")}</TableCell>
                  <TableCell align="center" sx={{ color: "#5dade2" }}>
                    {advertisement.phoneNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t("Advertisement Date")}</TableCell>
                  <TableCell align="center" sx={{ color: "#ec7063" }}>
                    {convertDate(advertisement.createdAt)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  </Container>

  );
};

export default AdvertisementData;
