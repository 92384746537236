import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AppBar, TableCell, tableCellClasses } from '@mui/material';
import instance from "./../../axios/instance";
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import Loading from "./../../components/Loading";
import { useSnackbar } from "notistack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';

function AdminMail() {
  const requestsArray = ["teacher/allTeachers", "student/all", "parent/all"];
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState();
  const { enqueueSnackbar } = useSnackbar();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function search(e) {
    const eleValue = e.target.value;
    setSearchValue(eleValue);
  }

  React.useEffect(() => {
    (async () => {
      try {
        const response = await instance.get(requestsArray[value]);
        const data = requestsArray[value] === "student/all" ? response.data.data : response.data;
        setData(data);
      } catch (err) {
        const errMsg = err.message === "timeout of 2000ms exceeded" ? "Server timeout responding" : err.response.data.message;
        enqueueSnackbar(errMsg, { variant: "error", autoHideDuration: "5000" })
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, enqueueSnackbar]);

  return (
    <AdminLayout>
      <Box sx={{ borderBottom: 1, bgcolor: 'background.paper', marginBottom: "70px" }}>
        <AppBar position='static'>
          <Tabs value={value} onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example">
            <Tab label={t("teachers")} />
            <Tab label={t("students")} />
            <Tab label={t("parents")} />
          </Tabs>
        </AppBar>
      </Box>
      {
        (!data.length) ? <Loading /> :
          <>
            <Paper
              sx={{ p: '2px 4px', marginBottom: '20px', display: 'flex', alignItems: 'center', width: "100%" }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t("search")}
                onChange={(e) => { search(e) }}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t("name")}</StyledTableCell>
                    <StyledTableCell align="right">{t("email")}</StyledTableCell>
                    <StyledTableCell align="right">{t("phone")}</StyledTableCell>
                    <StyledTableCell align="right">{t("mail")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((d) => {

                    // return <TableContent data={d} />
                    const name = (value === 0 ? `${d.firstName} ${d.lastName}` : d.name);
                    // Search functionality
                    if (searchValue) {
                      const matchName = (name.toLowerCase()).includes((searchValue.toLowerCase()));
                      if (matchName) {
                        return <TableContent data={d} name={name} />
                      }
                      return false;
                    } else {
                      return <TableContent data={d} name={name} />
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
      }
    </AdminLayout>
  )
}

export default AdminMail;


function TableContent({ data, name }) {
  const navigate = useNavigate();
  return <StyledTableRow key={data.id}>
    <StyledTableCell component="th" scope="row">{name}</StyledTableCell>
    <StyledTableCell align="right">{data.email}</StyledTableCell>
    <StyledTableCell align="right">{data.phone ? data.phone : data.phoneNumber || t("unknown")}</StyledTableCell>
    <StyledTableCell align="right">
      <IconButton color="primary" variant="contained" onClick={() => navigate("/admin/mail-form/" + data.email)}>
        <EmailIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
};



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));