import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Container,
    DialogActions,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import axios from "axios";
import VerifyOTP from "./verifyOTP";

export default function SendEmail({ type }) {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [loading,setLoading]=useState(true)
    const [emailValue,setEmail]=useState("")
    const lang = Cookies.get("i18next") || "en";
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            email:""
        },
    });

    async function onSubmit(data) {
        closeSnackbar();
        setEmail(data.email)
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        console.log([...formData]);

        try {
            const res=await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/otp/sendotp`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log(res);
            
            if (res.status === 200) {
                if(lang==="ar")
                {enqueueSnackbar(res.data.msg.arabic, { variant: "success", autoHideDuration: 8000 })}
                else
                {enqueueSnackbar(res.data.msg.english, { variant: "success", autoHideDuration: 8000 })}
                setLoading(false)

            }
            
        } catch (err) {
            enqueueSnackbar(t("Something went wrong"), { variant: "error", autoHideDuration: 8000 });
        }
    }

    return (
        <> 
        {loading?
                    <Container sx={{ marginTop: "110px", display: "flex", textAlign: "center" }}>
                    <Paper
                        sx={{
                            width: { md: "600px" },
                            padding: "30px 50px",
                            margin: "60px auto 400px",
                        }}
                    >
                        <Typography
                            sx={{
                                marginTop: "20px",
                                fontSize: "14px",
                                textAlign: "center",
                                fontWeight: "700",
                                marginBottom: "20px",
                            }}
                        >
                            {t("Please enter your email")}
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ marginBottom: "18px" }}>
                                <Controller
                                    name="titleArabic"
                                    control={control}
                                    render={({ field }) => <TextField  type="email"{...field} fullWidth />}
                                    {...register("email", {
                                        required: "email Address is required",
                                    })}
                                />
                                {errors.email?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <DialogActions >
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ ml: "6px", mr: "6px",textTransform: "capitalize",width:"100%"}}
                                >
                                    {t("send")}
                                </Button>
                            </DialogActions>
                        </form>
                    </Paper>
                </Container>
        :
        <VerifyOTP type={type} email={emailValue}/>
        }

        </>

    );
}
