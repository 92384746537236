import {useQuery} from 'react-query'

async function getQuestionsPending()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/status/pending-questions`
    )
    return response.json()
}

export const useAllQuestionsPending = ()=>
{
    return useQuery('get-pending-questions',()=>getQuestionsPending())
}