import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Advertisements() {
  const [subjects, setAllSubjects] = React.useState([]);
  
  React.useEffect(() => {
    (async () => {
      try {
        const response = await instance.get("advertisement/accept-advertisement");
        const data = response.data.data || [];
        setAllSubjects([...data]);
      } catch (error) {
        console.error("Failed to fetch advertisements:", error);
      }
    })();
  }, []);
  
  return (
    <Container sx={{ marginTop: "20px", padding: "16px" }}>
      <Box sx={{ width: '100%' }}>
        <h1 className="text-center text-3xl sm:text-4xl md:text-5xl font-bold mb-10 mt-10">
          {t("Advertisements")}
        </h1>
        {
          (!subjects.length) ? (
            <h1 className="text-xl sm:text-2xl text-sky-500 mt-10 text-center">
              {t("No advertisements available")}
            </h1>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
              {subjects.map((subject) => (
                <AdvertisementCard key={subject.id} subject={subject} />
              ))}
            </div>
          )
        }
      </Box>
    </Container>
  );
}

export default Advertisements;

function AdvertisementCard({ subject }) {
  const navigate = useNavigate();
  const lang = Cookies.get("i18next") || "en";

  return (
    <div className="rounded overflow-hidden shadow-lg bg-white transition-transform transform hover:scale-105">
      <div className="relative">
        <img
          className="object-cover h-48 w-full brightness-75"
          src={subject.image ? `${process.env.REACT_APP_API_KEY}images/${subject.image}` : "/logo.png"}
          alt="advertisement"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="drop-shadow text-yellow-300 text-lg sm:text-xl font-bold text-center">
            {lang === "ar" ? subject.titleArabic : subject.titleEnglish}
          </div>
        </div>
      </div>
      <div className="p-4">
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate(`/advert/${subject.id}`)}
          sx={{ backgroundColor: "#0288d1", ":hover": { backgroundColor: "#0277bd" } }}
        >
          {t("View more")}
        </Button>
      </div>
    </div>
  );
}
