import React from 'react'
import Navbar from '../../Navbar'
import LinksFooter from '../home/LinksFooter'
import Footer from '../home/Footer'
import JobsData from './JobsData'

export default function Careers() {
    return (
        <Navbar>
            <JobsData />
            <LinksFooter/>
            <Footer/>
        </Navbar>
    )
}
