import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Loading from './../../Loading';
import instance from "../../../axios/instance";
import { useNavigate } from 'react-router-dom';
import TeacherCard from '../../reusableUi/TeacherCard';

function TeacherTab() {
  const { enqueueSnackbar } = useSnackbar();
  const subjects = ["arabic", "mathematics", "english", "physics", "chemistry"];
  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const response = await instance(`teacher/findTeachers/${subjects[value]}`);
        setTeachers(response.data);
        setIsLoading(false);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error", autoHideDuration: "5000" });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, value]);

  return (
    <Container className='my-20'>
      <h1 className='text-center text-5xl mb-6'>{t("teacher topic")}</h1>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: "flex", justifyContent: "center", backgroundColor: "#005B8E", borderRadius: "15px" }}>
          <Tabs textColor="secondary"
            indicatorColor="secondary"
            value={value}
            variant="scrollable"
            onChange={handleChange}
            aria-label="full width tabs example"
          >
            {
              subjects.map((subject) => {
                return <Tab style=
                  {{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "white"
                  }} label={t(subject)} />
              })
            }
            <Tab style=
              {{
                fontSize: "15px",
                fontWeight: "bold",
                color: "white"
              }} label={t("specialties")} onClick={() => navigate("/teachers/specialties")} />
          </Tabs>
        </Box>
        {
          isLoading ?
            <Loading />
            :
            <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-6'>
              {
                teachers.length ?
                  teachers.map((teacher) => {
                    return (
                      <TeacherCard teacher={teacher} />
                    )
                  }) : <h1 className='text-red-500/50 text-center text-2xl'>{t("coming")}</h1>
              }
            </div>
        }
      </Box>
    </Container>
  )
}

export default TeacherTab;
