import {
  DialogContent,
  DialogActions,
  InputLabel,
  Button,
  Box,
  TextField,
  CardMedia,
  Avatar,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

export default function ViewResource({ handleClose, resource }) {
  const { t } = useTranslation();
  const lang = Cookies.get("i18next") || "en";
  console.log(resource);
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
  }

  return (
    <Box sx={{ width: "500px", maxWidth: "100%", paddingTop: "12px" }}>
      <h1 className="text-3xl text-center my-4">{t("View-Resource")}</h1>

      <DialogContent>
        <Box sx={{ marginBottom: "18px" ,display:"flex",justifyContent:"center"}}>
            <Avatar alt={resource.image} src={resource.image ? `${process.env.REACT_APP_API_KEY}images/${resource.image}` : "/logo.png"}   sx={{ width: 200, height: 200}}/>
        </Box>

        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("currency")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={t(`${resource?.currency}`) || ""}
          />
        </Box>
        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("resource")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={t(`${resource?.resource}`) || ""}
          />
        </Box>

        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("startDate")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={convertDate(resource?.startDate )|| ""}
          />
        </Box>
        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("End Date")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={convertDate(resource?.endDate) || ""}
          />
        </Box>
        {lang === "ar" ? (
          <>
            <Box sx={{ marginBottom: "18px" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                {t("level")}
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={resource?.level?.titleAR || ""}
              />
            </Box>
            <Box sx={{ marginBottom: "18px" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                {t("subject")}
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={resource?.subject?.titleAR || ""}
              />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ marginBottom: "18px" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                {t("level")}
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={resource?.level?.titleEN || ""}
              />
            </Box>
            <Box sx={{ marginBottom: "18px" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                {t("subject")}
              </InputLabel>
              <TextField
                disabled
                fullWidth
                value={resource?.subject?.titleEN || ""}
              />
            </Box>
          </>
        )}

        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("price")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={resource?.price || ""}
          />
        </Box>

        <Box sx={{ marginBottom: "18px" }}>
          <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
            {t("link")}
          </InputLabel>
          <TextField
            disabled
            fullWidth
            value={resource?.video || ""}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{t("cancel")}</Button>
      </DialogActions>
    </Box>
  );
}
