import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, AppBar, Toolbar, Slide } from '@mui/material';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import instance from '../../axios/instance';

const QuestionDetails = ({ questions, DialogSatus,nameAR,nameEN, onClose }) => {
  const [open, setOpen] = useState(DialogSatus);
  const [selectedAnswers, setSelectedAnswers] = useState({});  // لتخزين الإجابات لكل سؤال
  const [feedback, setFeedback] = useState({});
  const lang = Cookies.get("i18next") || "en";

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();  // Notify parent on close
  };

  const handleAnswerSelect = (questionId, answer) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [questionId]: answer, // حفظ الإجابة لكل سؤال
    }));

    setFeedback(prev => ({
      ...prev,
      [questionId]: answer.answerStatus === "Yes" ? "True" : "False",  // حفظ رد الفعل بناءً على الإجابة
    }));
  };

  useEffect(() => {
    setOpen(DialogSatus);
  }, [DialogSatus]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {lang === "ar" ? nameAR:nameEN}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            {t("close")}
          </Button>
        </Toolbar>
      </AppBar>
      {questions.map((question) => (
        <React.Fragment key={question.id}>
          <DialogTitle>
            <div className="flex justify-center mt-5">
              <h1 className="text-center text-xl mt-4">
                {lang === "ar" ? question.titleArabic : question.titleEnglish}
              </h1>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
              {question.answers.map((answer) => (
                <div key={answer.id}>
                  <Button
                    variant="outlined"
                    onClick={() => handleAnswerSelect(question.id, answer)} // تمرير معرف السؤال والإجابة
                    sx={{ width: "100%", height: "50px" }}
                  >
                    {lang === "ar" ? answer.titleArabic : answer.titleEnglish}
                  </Button>
                </div>
              ))}
            </div>
            {selectedAnswers[question.id] && (
              <div className="mt-4">
                <Typography variant="h5">
                  {t("selectedAnswer")}: {lang === "ar" ? selectedAnswers[question.id].titleArabic : selectedAnswers[question.id].titleEnglish}
                </Typography>
                <Typography variant="h6" color={feedback[question.id] === "True" ? "green" : "red"}>
                  {t("feedback")}: {t(feedback[question.id])}
                </Typography>
              </div>
            )}
          </DialogContent>
        </React.Fragment>
      ))}
    </Dialog>
  );
};

const QuestionsDetails = ({ id,nameAR,nameEN}) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [DialogStatus, setOpen] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await instance(`student/getMyQuestions/${id}`);
        setQuestions(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('حدث خطأ أثناء جلب البيانات');
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [id]);

  if (loading) return <p>{t("No questions available now")}</p>;
  if (error) return <p>{error}</p>;
console.log(questions);

  return (
    <>
      <Button variant="contained" size="small" sx={{ height: "50px" }} onClick={() => setOpen(true)}>
        {t("details")}
      </Button>
      <QuestionDetails questions={questions} DialogSatus={DialogStatus}  nameAR={nameAR} nameEN={nameEN}onClose={() => setOpen(false)} />
    </>
  );
};

export default QuestionsDetails;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
