import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import FormControl from '@mui/material/FormControl';
import { Container, TextField, Select, Box, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import currencies from '../../data/currencies';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import Alert from '@mui/material/Alert';
import axios from 'axios';

function AddPackage() {
  const [levels, setLevels] = useState([]);
  const [error, setError] = useState(null);
  const lang = Cookies.get("i18next") || "en";
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState();
  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const { teacher } = useSelector((state) => state.teacher);
  const i18next = Cookies.get('i18next');

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/levels`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setLevels(data.data); // Assuming the API returns an array of levels
      } catch (err) {
        console.error("Error fetching levels:", err);
        setError(err.message);
      }
    };

    fetchLevels();
  }, []); // The empty dependency array ensures it runs only once when the component mounts
  console.log(levels);


  const navigate = useNavigate();
  const hiddenStyle = {
    display: "none"
  }
  const { register, handleSubmit, formState: { errors }, control, getValues } = useForm({
    defaultValues: {
      level: '',
      titleAr: '',
      titleEn: '',
      duration: '',
      price: '',
      currency: '',
      startDate: '',
      endDate: '',
      packageType: '',
      numberOfShares: '',
      sharesInWeek: '',
      descriptionAr: '',
      descriptionEn: '',
      teacherId: '',
    }
  });
  
  function handleFile(e) {
    setFile(e.target.files[0]);
    console.log(file);
    
  }
  
  async function createPackage(data) {
    const startDateTime = new Date(data.startDate); // تحويل إلى كائن تاريخ
    const endDateTime = new Date(data.endDate); // تحويل إلى كائن تاريخ

    // تحقق من أن startDate أقل من endDate
    if (startDateTime >= endDateTime) {
        enqueueSnackbar(t("Start date must be earlier than end date"), { variant: "error", autoHideDuration: 5000 });
        return; // إنهاء الوظيفة إذا كان الشرط غير محقق
    }

    data.startDate = startDateTime;
    data.endDate = endDateTime;
    delete data.time;

    const formData = new FormData();

    formData.append("image", file);
    formData.append("teacherId", teacher.id);
    for (const key in data) {
        if (key !== "image" && key !== "teacherId") { // تجنب إضافة الصورة مرتين
            formData.append(key, data[key]);
        }
    }

    console.log([...formData]); // للتحقق من البيانات 

    try {
        await axios.post(`${process.env.REACT_APP_API_KEY}api/v1/packages/create-package`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        setLoading(true);
        enqueueSnackbar(t("packageMsg"), { variant: "success", autoHideDuration: 5000 });
        navigate("/teacher/package")

    } catch (err) {
        enqueueSnackbar("Data submission failed", { variant: "error", autoHideDuration: 5000 });
        setLoading(false);
        console.error("Error:", err);
        throw new Error("Something went wrong: " + err);
    }
}

  
  return (
    <Navbar>
      <Container sx={{ marginTop: '120px', marginBottom: '80px' }}>
        <Alert severity="warning" sx={close ? { ...hiddenStyle } : {}} onClose={() => { setClose(true) }}>
          {t("image")}
        </Alert>
        <h1 className="text-3xl text-center my-4">{t("add_package")}</h1>
        <form style={{ maxWidth: "50%", margin: 'auto' }} encType="multipart/form-data" onSubmit={handleSubmit(createPackage)}>
          <FormControl fullWidth margin="dense">
            <TextField
              label={t("packageTitleArabic")}
              type="text"
              variant="outlined"
              {...register("titleAr", {
                required: t("isRequired"),
                minLength: {
                  value: 5,
                  message: t("titleLimit")
                }
              })} />
            <p className='text-red-500'>{errors.title?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField
              label={t("packageTitleEnglish")}
              type="text"
              variant="outlined"
              {...register("titleEn", {
                required: t("isRequired"),
                minLength: {
                  value: 5,
                  message: t("titleLimit")
                }
              })} />
            <p className='text-red-500'>{errors.title?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="level">{t("choosesLevel")}</InputLabel>
            <Select
              labelId="level"
              label={t("choosesLevel")}
              defaultValue="" // قيمة افتراضية
              {...register("level", {
                required: t("isRequired"),
              })}
            >
              {i18next === "ar"
                ? levels.map((level, index) => (
                  <MenuItem key={index} value={level.id}>{t(level.titleAR)}</MenuItem>
                ))
                : levels.map((level, index) => (
                  <MenuItem key={index} value={level.id}>{t(level.titleEN)}</MenuItem>
                ))
              }
            </Select>
            <p className="text-red-500">{errors.level?.message}</p>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <TextField
              label={t("sharePrice")}
              type="number"
              variant="outlined"
              {...register("price", {
                required: t("isRequired"),
              })} />
            <p className='text-red-500'>{errors.price?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="currency">{t("currency")}</InputLabel>
            <Select
              labelId="currency"
              label={t("currency")}
              {...register("currency", {
                required: t("isRequired"),
              })}
            >
              {
                currencies.map((curr) => {
                  return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                })
              }
            </Select>
            <p className='text-red-500'>{errors.currency?.message}</p>
          </FormControl>
          <Box >
            <Controller
              name="packageType"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth margin="dense">
                  <InputLabel id="packageType">{t("lessonType")}</InputLabel>
                  <Select
                    {...field}
                    labelId="packageType"
                    label={t("lessonType")}
                    {...register("packageType", {
                      required: "type is required",
                    })}
                  >
                    <MenuItem value={"online"}>
                      {lang === "ar" ? "عن بعد" : "online"}
                    </MenuItem>
                    <MenuItem value={"student"}>
                      {lang === "ar" ? "في منزل الطالب" : "Student home"}
                    </MenuItem>
                    <MenuItem value={"teacher"}>
                      {lang === "ar" ? "في منزل المعلم" : "Teacher home"}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {errors.typeLesson?.type === "required" && (
              <Typography
                color="error"
                role="alert"
                sx={{ fontSize: "13px", marginTop: "6px" }}
              >
                {t("required")}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box sx={{ width: "100%" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                {t("lessonDate")}
              </InputLabel>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <TextField type="date" {...field} fullWidth />
                )}
                {...register("startDate", {
                  required: "start Date Address is required",
                })}
              />
              {errors.date?.type === "required" && (
                <Typography
                  color="error"
                  role="alert"
                  sx={{ fontSize: "13px", marginTop: "6px" }}
                >
                  {t("required")}
                </Typography>
              )}
            </Box>
            {/* ------------------------------- */}
            <Box sx={{ width: "100%" }}>
              <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                {t("lessonTime")}
              </InputLabel>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <TextField type="time" {...field} fullWidth />
                )}
                {...register("time", {
                  required: "time is required",
                })}
              />
              {errors.time?.type === "required" && (
                <Typography
                  color="error"
                  role="alert"
                  sx={{ fontSize: "13px", marginTop: "6px" }}
                >
                  {t("required")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px", marginTop: "3px" }}>
              {t("lessonEndDate")}
            </InputLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <TextField type="date" {...field} fullWidth />
              )}
              {...register("endDate", {
                required: "End Date Address is required",
              })}
            />
            {errors.date?.type === "required" && (
              <Typography
                color="error"
                role="alert"
                sx={{ fontSize: "13px", marginTop: "6px" }}
              >
                {t("required")}
              </Typography>
            )}
          </Box>
          <FormControl fullWidth margin="dense">
            <TextField
              label={t("sharesCount")}
              type="number"
              variant="outlined"
              {...register("numberOfShares", {
                required: t("isRequired"),
                minLength: {
                  value: 1,
                  message: t("share1")
                }
              })} />
            <p className='text-red-500'>{errors.numberOfShares?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <TextField label={t("sharesCountInWeek")} type="number" variant="outlined" {...register("sharesInWeek", {
              required: t("isRequired"),
              validate: (value) => {
                if (value > Number(getValues("numberOfShares"))) {
                  return t("shareInWeekValid")
                }
              },
              pattern: {
                value: /^[1-9]$/,
                message: t("share1")
              },
            })} />
            <p className='text-red-500'>{errors.sharesInWeek?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="duration">{t("duration")}</InputLabel>
            <Select
              labelId="duration"
              label={t("duration")}
              {...register("duration", {
                required: t("isRequired"),
              })}
            >
              <MenuItem value={1}>1 {t("hour")}</MenuItem>
              <MenuItem value={2}>2 {t("hour")}</MenuItem>
            </Select>
            <p className='text-red-500'>{errors.duration?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <textarea className='w-full h-28 bg-gray-100 rounded outline-0 ring-2 ring-gray-200 focus:ring-blue-900' placeholder={t("descriptionArea")} {...register("descriptionAr",
              {
                required: t("isRequired"),
              }
            )} ></textarea>
            <p className='text-red-500'>{errors.descriptionAr?.message}</p>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <textarea className='w-full h-28 bg-gray-100 rounded outline-0 ring-2 ring-gray-200 focus:ring-blue-900' placeholder={t("descriptionAreaEnglish")} {...register("descriptionEn",
              {
                required: t("isRequired"),
              }
            )} ></textarea>
            <p className='text-red-500'>{errors.descriptionEn?.message}</p>
          </FormControl>
          <FormControl fullWidth margin='dense'>
            <input type="file" onChange={handleFile} />
          </FormControl>
          <br />
          <br />
          <LoadingButton
            type="submit"
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >{t("add_package")}</LoadingButton>
        </form>
      </Container>
    </Navbar>
  )
}

export default AddPackage;