import { Chip, Container, Divider } from '@mui/material'
import React from 'react'
import PackageDetails from '../../components/reusableUi/PackageDetails'
import { useMyStudentPackages } from '../../hooks/useMyStudentPackages';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import Navbar from '../../components/Navbar';

function TeacherPackages() {
  const { studentId } = useParams();
  const data = useMyStudentPackages(studentId).data || [];

  return <Navbar>
    <Container sx={{ marginTop: "200px" }}>
      {
        data.map((data) => {
          return <>
            <section className='grid grid-cols-3 gap-4'>
              <div className='col-span-2'>
                <h1 className='text-2xl'>{t("package")}: {data.title}</h1>
                <p className='truncate text-lg'>{t("description")}: {data.description}</p>
              </div>
              <PackageDetails data={data} teacherButton={false} />
            </section>
            <Divider ><Chip label="End" size='small' /></Divider>
          </>
        })
      }

    </Container>
  </Navbar>
}
export default TeacherPackages;