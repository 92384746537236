import {useQuery} from 'react-query'

async function getExamsPending()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/exams/status/pending-exams`
    )
    return response.json()
}

export const useAllExamsPending = ()=>
{
    return useQuery('get-pending-exams',()=>getExamsPending())
}