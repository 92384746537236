import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import FormControl from '@mui/material/FormControl';
import { Container, TextField, Select, Box, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import currencies from '../../data/currencies';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';

function EditPackage() {
    const { id } = useParams()
    const [levels, setLevels] = useState([]);
    const [error, setError] = useState(null);
    const lang = Cookies.get("i18next") || "en";
    const { enqueueSnackbar } = useSnackbar();
    const [file, setFile] = useState();
    const [close, setClose] = useState(false);
    const [Package, setPackage] = useState()
    const [loading, setLoading] = useState(false);
    const { teacher } = useSelector((state) => state.teacher);
    const i18next = Cookies.get('i18next');
    let [LevelData, setLevelData] = useState([]);

    const { register, handleSubmit, formState: { errors }, control, getValues, reset } = useForm({
        defaultValues: {
            level: '',
            titleAr: '',
            titleEn: '',
            duration: '',
            price: '',
            currency: '',
            startDate: '',
            endDate: '',
            packageType: '',
            numberOfShares: '',
            sharesInWeek: '',
            descriptionAr: '',
            descriptionEn: '',
            teacherId: '',
        }
    });

    useEffect(() => {
        const fetchLevels = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/admin/levels`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setLevels(data.data);
                for (const key in levels) {
                    if (levels[key].id === Package.level) {
                        console.log("true");
                        setLevelData(levels[key]);
                    }
                }
            } catch (err) {
                console.error("Error fetching levels:", err);
                setError(err.message);
            }
        };

        const fetchPackageData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/packages/get-package/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setPackage(data); // Assuming the package contains a level property
                setLoading(true)
            } catch (err) {
                setError(err.message);
            }
        };

        // Fetch data
        fetchLevels();
        fetchPackageData();
    }, [id]);

    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
    }
    useEffect(() => {
        if (Package?.startDate && Package?.endDate) {
            console.log(Package);
            reset({
                level: Package.level,
                titleAr: Package.titleAr,
                titleEn: Package.titleEn,
                duration: Package.duration,
                price: Package.price,
                currency: Package.currency,
                startDate: convertDate(Package.startDate),
                endDate: convertDate(Package.endDate),
                packageType: Package.packageType,
                numberOfShares: Package.numberOfShares,
                sharesInWeek: Package.sharesInWeek,
                descriptionAr: Package.descriptionAr,
                descriptionEn: Package.descriptionEn,
                teacherId: Package.teacherId,
            });
        }
    }, [Package, reset]); // هنا، سيتم تنفيذ هذا الـ useEffect عند تغيير الـ Package فقط



    const navigate = useNavigate();
    const hiddenStyle = {
        display: "none"
    }
 

    function handleFile(e) {
        setFile(e.target.files[0]);
        console.log(file);

    }

    async function createPackage(data) {

        const startDateTime = new Date(data.startDate); // تحويل إلى كائن تاريخ
        const endDateTime = new Date(data.endDate); // تحويل إلى كائن تاريخ

        // // تحقق من أن startDate أقل من endDate
        if (startDateTime >= endDateTime) {
            enqueueSnackbar(t("Start date must be earlier than end date"), { variant: "error", autoHideDuration: 5000 });
            return; // إنهاء الوظيفة إذا كان الشرط غير محقق
        }

        data.startDate = startDateTime;
        data.endDate = endDateTime;

        const formData = new FormData();

        formData.append("image", file);
        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "image" && key !== "teacherId") { // تجنب إضافة الصورة مرتين
                formData.append(key, data[key]);
            }
        }

        console.log([...formData]); // للتحقق من البيانات 

        try {
            await axios.put(`${process.env.REACT_APP_API_KEY}api/v1/packages/edit-package/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            enqueueSnackbar(t("packageEditMsg"), { variant: "success", autoHideDuration: 5000 });
            navigate("/teacher/package")

        } catch (err) {
            enqueueSnackbar("Data submission failed", { variant: "error", autoHideDuration: 5000 });
            setLoading(false);
            console.error("Error:", err);
            throw new Error("Something went wrong: " + err);
        }
    }
  

    return (
        <Navbar>
            <Container sx={{ marginTop: '120px', marginBottom: '80px' }}>
                <h1 className="text-3xl text-center my-4">{t("Edit Package")}</h1>
                <form style={{ maxWidth: "50%", margin: 'auto' }} encType="multipart/form-data" onSubmit={handleSubmit(createPackage)}>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleAr")}
                        </InputLabel>
                        <Controller
                            name="titleAr"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("titleEn")}
                        </InputLabel>
                        <Controller
                            name="titleEn"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("level")}
                            </InputLabel>
                            <Controller
                                name="level"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                    {...field}
                                    fullWidth
                                    {...register("level", {
                                        required: t("isRequired"),
                                    })}
                                >
                                    {i18next === "ar"
                                        ? levels.map((level, index) => (
                                            <MenuItem key={index}  value={level.id}>{t(level.titleAR)}</MenuItem>
                                        ))
                                        : levels.map((level, index) => (
                                            <MenuItem key={index} value={level.id}>{t(level.titleEN)}</MenuItem>
                                        ))
                                    }
                                </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("price")}
                        </InputLabel>
                        <Controller
                            name="price"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("currency")}
                            </InputLabel>
                            <Controller
                                name="currency"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                        {...register("currency", {
                                            required: t("isRequired"),
                                        })}
                                    >
                                        {
                                            currencies.map((curr) => {
                                                return <MenuItem value={curr.title}>{lang === "en" ? curr.titleEn : curr.titleAr}</MenuItem>
                                            })
                                        }
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("packageType")}
                        </InputLabel>
                        <Controller
                            name="packageType"
                            control={control}
                            render={({ field }) => (
                                <Select
                                {...field}
                                fullWidth
                                {...register("packageType", {
                                    required: t("isRequired"),
                                })}
                            >
                                        <MenuItem value={"online"}>
                                            {lang === "ar" ? "عن بعد" : "online"}
                                        </MenuItem>
                                        <MenuItem value={"student"}>
                                            {lang === "ar" ? "في منزل الطالب" : "Student home"}
                                        </MenuItem>
                                        <MenuItem value={"teacher"}>
                                            {lang === "ar" ? "في منزل المعلم" : "Teacher home"}
                                        </MenuItem>
                            </Select>
                            )}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px"  }}>
                        <Box sx={{ width: "100%" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "13px" }}>
                                {t("lessonDate")}
                            </InputLabel>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => (
                                    <TextField type="date" {...field} fullWidth />
                                )}
                                {...register("startDate", {
                                    required: "start Date Address is required",
                                })}
                            />
                            {errors.date?.type === "required" && (
                                <Typography
                                    color="error"
                                    role="alert"
                                    sx={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                    {t("required")}
                                </Typography>
                            )}
                        </Box>
                        {/* ------------------------------- */}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "13px", marginTop: "3px" }}>
                            {t("lessonEndDate")}
                        </InputLabel>
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field }) => (
                                <TextField type="date" {...field} fullWidth />
                            )}
                            {...register("endDate", {
                                required: "End Date Address is required",
                            })}
                        />
                        {errors.date?.type === "required" && (
                            <Typography
                                color="error"
                                role="alert"
                                sx={{ fontSize: "13px", marginTop: "6px" }}
                            >
                                {t("required")}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("sharesCount")}
                        </InputLabel>
                        <Controller
                            name="numberOfShares"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("sharesCountInWeek")}
                        </InputLabel>
                        <Controller
                            name="sharesInWeek"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("duration")}
                        </InputLabel>
                        <Controller
                            name="duration"
                            control={control}
                            render={({ field }) => <TextField  {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionArea")}
                        </InputLabel>
                        <Controller
                            name="descriptionAr"
                            control={control}
                            render={({ field }) => <TextField multiline rows={5} {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <Box sx={{ marginBottom: "18px" }}>
                        <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                            {t("descriptionAreaEnglish")}
                        </InputLabel>
                        <Controller
                            name="descriptionEn"
                            control={control}
                            render={({ field }) => <TextField multiline rows={5} {...field} fullWidth />}
                            rules={{ required: t("required") }}
                        />
                    </Box>
                    <FormControl fullWidth margin='dense'>
                        <input type="file" onChange={handleFile} />
                    </FormControl>
                    <br />
                    <br />
                    <LoadingButton
                        type="submit"
                        variant="contained"
                    >{t("Edit Package")}
                    </LoadingButton>
                </form>
            </Container>
        </Navbar>
    )
}

export default EditPackage;
