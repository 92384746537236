import React from 'react';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { t } from 'i18next';
import AdminLayout from '../../components/admin/AdminLayout';
import instance from "./../../axios/instance";
import PackageDetails from '../../components/reusableUi/PackageDetails';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSnackbar } from "notistack";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Cookies from "js-cookie";
import Loading from "./../../components/Loading";

function PendingPackages() {
  const [data, setData] = React.useState(null);
  const [rebuild, setRebuild] = React.useState(false);
  const lang = Cookies.get("i18next") || "en";
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    (async () => {
      const response = await instance("packages/unVerifiedPackages");
      const data = response.data.data;
      setData(data);
    })();
  }, [rebuild]);

  async function verifiedPackage(id) {
    try {
      const res = await instance.patch("packages/verified/" + id);
      const message = lang === "en" ? res.data.message.en : res.data.message.ar;
      setRebuild(!rebuild);
      enqueueSnackbar(message, { variant: "success", autoHideDuration: "5000" });
    } catch {
      enqueueSnackbar(t("error"), { variant: "error", autoHideDuration: "5000" });
    }
  }

  return (
    <AdminLayout>
      {
        (data === null) ? <Loading /> : (!data.length) ? <h1 className='text-center text-2xl text-sky-500 mt-20'>{t("noPackage")}</h1> :
          data.map((p) => {
            return <div className='grid grid-cols-3 gap-40 p-5 shadow mb-5'>
              <section className='grid col-span-2'>
                <h1 className='text-lg'>{t("package")}:  {" "}
                  {lang ==="ar"?
                    <>
                      {p.titleAr}
                    </>
                    :
                    <>
                      {p.titleEn}
                    </>
                }
                </h1>
                <div className='flex justify-evenly'>
                  <IconButton color='success' onClick={() => verifiedPackage(p.id)}>
                    <CheckIcon />
                  </IconButton>
                  <AlertDialogSlide id={p.id} rebuild={rebuild} setRebuild={setRebuild}/>
                </div>
              </section>
              <section className='grid col-span-1'>
                <PackageDetails data={p} teacherButton={true} />
              </section>
            </div>
          })
      }

    </AdminLayout>
  )
}

export default PendingPackages;

function AlertDialogSlide({ id, rebuild, setRebuild }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const lang = Cookies.get("i18next") || "en";

  async function unVerifiedPackage(id) {
    try {
      const res = await instance.delete("packages/unVerified/" + id);
      const message = lang === "en" ? res.data.message.en : res.data.message.ar;
      setRebuild(!rebuild);
      enqueueSnackbar(message, { variant: "success", autoHideDuration: "5000" });
    } catch {
      enqueueSnackbar(t("error"), { variant: "error", autoHideDuration: "5000" });
    }
    handleClose();
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton color='error' onClick={handleClickOpen}>
        <ClearIcon />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><RemoveCircleIcon /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("deleteAlert")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>{t("disagree")}</Button>
          <Button variant="contained" color="error" onClick={() => unVerifiedPackage(id)}>{t("agree")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});