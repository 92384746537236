import {useQuery} from 'react-query'

async function getWaitingAdvertisement()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/advertisement/pending-advertisement`
    )
    return response.json()
}

export const useWaitingAdvertisement = ()=>
{
    return useQuery('get-waiting-advertisement',()=>getWaitingAdvertisement())
}