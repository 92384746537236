import {
    Box,
    Button,
    Container,
    DialogActions,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../Navbar";
import Loading from "../Loading";
import AdminLayout from "./AdminLayout";

export default function EditDiscountByAdmin() {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [file, setFile] = useState();
    const [discount, setDiscount] = useState()
    const [isLoading, setLoading] = useState(false)
    const {id}=useParams()
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
    }
    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            titleArabic: "",
            titleEnglish: "",
            discountType: "",
            discountRate: "",
            amountBeforeDiscount: "",
            amountAfterDiscount: "",
            descriptionArabic: "",
            descriptionEnglish: "",
            discountStartDate: "",
            discountEndDate: "",
            termsAndConditionsArabic: "",
            termsAndConditionsEnglish: "",
            image:null
        },
    });
    const lang = Cookies.get("i18next") || "en";
    function handleFile(e) {
        setFile(e.target.files[0]);
    }
    const navigate = useNavigate();
    const handleClose=()=>{
        navigate("/admin/discounts")
    }
    useEffect(() => {
        const fetchDiscount = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/${id}`);   
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();        
                setDiscount(data.data);
                setLoading(true)
            } catch (err) { 
                console.error("Error fetching levels:", err);
            }
        };
        fetchDiscount();
    }, [id,lang])
    useEffect(() => {
        if (discount) {
            reset({
                titleArabic: discount.titleArabic,
                titleEnglish: discount.titleEnglish,
                discountType: discount.discountType,
                discountRate: discount.discountRate,
                amountBeforeDiscount: discount.amountBeforeDiscount,
                amountAfterDiscount: discount.amountAfterDiscount,
                descriptionArabic:discount.descriptionArabic,
                descriptionEnglish:discount.descriptionEnglish,
                discountStartDate:convertDate(discount.discountStartDate),
                discountEndDate:convertDate(discount.discountEndDate),
                termsAndConditionsArabic: discount.termsAndConditionsArabic,
                termsAndConditionsEnglish: discount.termsAndConditionsEnglish,
                image:discount.image || null,
            });
        }
    }, [discount, reset]);

    async function onSubmit(data) {
        closeSnackbar();
        const startDateTime = new Date(data.discountStartDate);
        const endDateTime = new Date(data.discountEndDate);

        if (startDateTime >= endDateTime) {
            enqueueSnackbar(t("Start date must be earlier than end date"), { variant: "error", autoHideDuration: 5000 });
            return;
        }
        data.discountStartDate = startDateTime;
        data.discountEndDate = endDateTime;
        const formData = new FormData();
        if (file) {
            formData.append("image", file);
        } else if (discount?.image) {
            
            formData.append("image", discount.image);
        }
        formData.append("teacherId", discount.teacherId);
        for (const key in data) {
            if (key !== "image" && key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }
        try {
            await axios.put(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/discounts/edit-discount/${id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("A new Discount has been updated."), { variant: "success", autoHideDuration: 8000 });
            navigate("/admin/discounts")
        } catch (err) {
            enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 8000 });
        }
    }
    const discountRate = watch("discountRate");
    const amountBeforeDiscount = watch("amountBeforeDiscount");
    useEffect(() => {
        if (discountRate && amountBeforeDiscount) {
            const discount = (amountBeforeDiscount * discountRate) / 100;
            const amountAfterDiscount = amountBeforeDiscount - discount;
            setValue("amountAfterDiscount", amountAfterDiscount.toFixed(2)); // تحديث القيمة
        }
    }, [discountRate, amountBeforeDiscount, setValue]);
    return (
        <>
            <AdminLayout>
                {isLoading?
                <>
                <Container sx={{ marginTop: '20px', marginBottom: '80px' }}>
                    <h1 className="text-3xl text-center my-4">{t("Edit Discount")}</h1>
                    <form onSubmit={handleSubmit(onSubmit)}  >
                        <Box sx={{ marginBottom: "18px", display: "flex", width: "100%", gap: "16px" }}>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("titleAr")}
                                </InputLabel>
                                <Controller
                                    name="titleArabic"
                                    control={control}
                                    render={({ field }) => <TextField {...field} fullWidth />}
                                    {...register("titleArabic", {
                                        required: "title Address is required",
                                    })}
                                />
                                {errors.titleArabic?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("titleEn")}
                                </InputLabel>
                                <Controller
                                    name="titleEnglish"
                                    control={control}
                                    render={({ field }) => <TextField {...field} fullWidth />}
                                    {...register("titleEnglish", {
                                        required: "title Address is required",
                                    })}
                                />
                                {errors.titleEnglish?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("Discount Type")}
                            </InputLabel>
                            <Controller
                                name="discountType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        fullWidth
                                    >
                                        <MenuItem value="Monthly Discounts">{t("Monthly Discounts")}</MenuItem>
                                        <MenuItem value="Discounts for University Cardholders">{t("Discounts for University Cardholders")}</MenuItem>
                                    </Select>
                                )}
                                rules={{ required: t("required") }}
                            />
                        </Box>
                        <Box sx={{ marginBottom: "18px", display: "flex", width: "100%", gap: "16px" }}>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Discount Percentage")}
                                </InputLabel>
                                <Controller
                                    name="discountRate"
                                    control={control}
                                    render={({ field }) => <TextField type="number"  {...field} fullWidth />}
                                    {...register("discountRate", {
                                        required: "discountRate is required",
                                    })}
                                />
                                {errors.discountRate?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Amount Before Discount")}
                                </InputLabel>
                                <Controller
                                    name="amountBeforeDiscount"
                                    control={control}
                                    render={({ field }) => <TextField type="number" {...field} fullWidth />}
                                    {...register("amountBeforeDiscount", {
                                        required: "amountBeforeDiscount is required",
                                    })}
                                />
                                {errors.amountBeforeDiscount?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Amount After Discount")}
                                </InputLabel>
                                <Controller
                                    name="amountAfterDiscount"
                                    control={control}
                                    render={({ field }) => <TextField type="number" disabled {...field} fullWidth />}
                                    {...register("amountAfterDiscount", {
                                        required: "amountAfterDiscount is required",
                                    })}
                                />
                                {errors.amountAfterDiscount?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: "18px", display: "flex", width: "100%", gap: "16px" }}>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("discount StartDate")}
                                </InputLabel>
                                <Controller
                                    name="discountStartDate"
                                    control={control}
                                    render={({ field }) => <TextField type="date" {...field} fullWidth />}
                                    {...register("discountStartDate", {
                                        required: "discountStartDate is required",
                                    })}
                                />
                                {errors.discountStartDate?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("discount EndDate")}
                                </InputLabel>
                                <Controller
                                    name="discountEndDate"
                                    control={control}
                                    render={({ field }) => <TextField type="date" {...field} fullWidth />}
                                    {...register("discountEndDate", {
                                        required: "discountEndDate is required",
                                    })}
                                />
                                {errors.discountEndDate?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: "18px", display: "flex", width: "100%", gap: "16px" }}>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("description Arabic")}
                                </InputLabel>
                                <Controller
                                    name="descriptionArabic"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("descriptionArabic", {
                                        required: "descriptionArabic is required",
                                    })}

                                />
                                {errors.descriptionArabic?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("description English")}
                                </InputLabel>
                                <Controller
                                    name="descriptionEnglish"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("descriptionEnglish", {
                                        required: "descriptionEnglish is required",
                                    })}
                                />
                                {errors.descriptionEnglish?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: "18px", display: "flex", width: "100%", gap: "16px" }}>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("terms And Conditions English")}
                                </InputLabel>
                                <Controller
                                    name="termsAndConditionsEnglish"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("termsAndConditionsEnglish", {
                                        required: "termsAndConditionsEnglish is required",
                                    })}
                                />
                                {errors.termsAndConditionsEnglish?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("terms And Conditions Arabic")}
                                </InputLabel>
                                <Controller
                                    name="termsAndConditionsArabic"
                                    control={control}
                                    render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                    {...register("termsAndConditionsArabic", {
                                        required: "termsAndConditionsArabic is required",
                                    })}
                                />
                                {errors.termsAndConditionsArabic?.type === "required" && (
                                    <Typography
                                        color="error"
                                        role="alert"
                                        sx={{ fontSize: "13px", marginTop: "6px" }}
                                    >
                                        {t("required")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("chooseImage")}
                            </InputLabel>
                            <input type="file" onChange={handleFile} />
                        </Box>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ ml: "6px", mr: "10px" }}
                            >
                                {t("save")}
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
                                onClick={()=>handleClose()}
                                sx={{ ml: "6px", mr: "10px" }}
                            >
                                {t("cancel")}
                            </Button>
                        </DialogActions>
                    </form>
                </Container>
                </>:
                <>
                <Loading />
                </>}

            </AdminLayout>
        </>
    );
}
