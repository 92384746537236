import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AdminLayout from '../../components/admin/AdminLayout';
import { t } from 'i18next';
import instance from './../../axios/instance';
import { Button } from '@mui/material';
import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AdminAdvertisement() {
  const { enqueueSnackbar } = useSnackbar();
  const [advertisements, setadvertisements] = useState([]);
  useEffect(() => {
    (async () => {
      // Get all rates
      const response = await instance.get("advertisement/accept-advertisement");
      const data = response.data.data || [];
      setadvertisements(data);
    }
    )();
  },[]);
  
  async function deleteAdvertisement(id) {
    try {
      await instance.delete(`advertisement/${id}`);
      enqueueSnackbar("تم الحذف بنجاح.", { variant: "success", autoHideDuration: "8000" });
      const response = await instance.get("advertisement/");
      const data = response.data.data || [];
      setadvertisements(data);
    } catch {
      enqueueSnackbar("حدث خطأ ما", { variant: "error", autoHideDuration: "8000" });
    }
  }

  return (
    <AdminLayout>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Image Advertisement")}</StyledTableCell>
              <StyledTableCell align="right">{t("title")}</StyledTableCell>
              <StyledTableCell align="right">{t("description")}</StyledTableCell>
              <StyledTableCell align="right">{t("Advertiser Name")}</StyledTableCell>
              <StyledTableCell align="right">{t("phone")}</StyledTableCell>
              <StyledTableCell align="right">{t("country")}</StyledTableCell>
              <StyledTableCell align="right">{t("delete")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advertisements.map((advertisement) => (
              <StyledTableRow key={advertisement.id}>
                <StyledTableCell component="th" >
                  <img src={advertisement.image ? `${process.env.REACT_APP_API_KEY}images/${advertisement.image}` : "/logo.png"} alt={advertisement.image} width={"100px"} />
                </StyledTableCell>
                <StyledTableCell align="right">{advertisement.titleArabic}</StyledTableCell>
                <StyledTableCell align="right">{advertisement.descriptionArabic}</StyledTableCell>
                <StyledTableCell align="right">{advertisement.advertiserName}</StyledTableCell>
                <StyledTableCell align="right">{advertisement.phoneNumber}</StyledTableCell>
                <StyledTableCell align="right">{advertisement.country}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button variant="contained" color='error' onClick={() => { deleteAdvertisement(advertisement.id) }}>{t("delete")}</Button>
                </StyledTableCell>
              </StyledTableRow>
            ))} 
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  )
}

export default AdminAdvertisement;

