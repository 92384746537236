import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import Loading from "../../components/Loading";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Navbar from "../../components/Navbar";
import { useMyTeacherPackages } from "../../hooks/useMyTeacherPackages";
import { useSelector } from "react-redux";
import { Delete, Edit} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


export default function MyPackages() {
  const lang = Cookies.get("i18next") || "en";
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { teacher } = useSelector((state) => state.teacher)
  const { data, isLoading } = useMyTeacherPackages(teacher.id);
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.data) {
      setPackages(data.data);
    }
  }, [data]);
  console.log(packages);

  const columns = [
    { id: "Image Job", label: t("Image Job"), minWidth: 150 },
    { id: "Title", label: t("title"), minWidth: 150 },
    { id: "price", label: t("price"), minWidth: 150 },
    { id: "description", label: t("description"), minWidth: 150 },
    { id: "status", label: t("status"), minWidth: 150 },
    { id: "startData", label: t("startDate"), minWidth: 150 },
    { id: "endData", label: t("endData"), minWidth: 150 },
    { id: "update", label: t("update"), minWidth: 150 },
    { id: "delete", label: t("delete"), minWidth: 150 },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // الأشهر تبدأ من 0 لذا نضيف 1
    const day = String(date.getDate()).padStart(2, '0'); // تأكد من أن اليوم يتكون من رقمين
    return `${year}-${month}-${day}`;
};

  async function EditPackage(id) {
    navigate(`/teacher/edit-package/${id}`)
  }

  async function DeletePackage(id) {
    closeSnackbar();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}api/v1/packages/delete-package/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        enqueueSnackbar(t("The package has been removed."), {
          variant: "error",
          autoHideDuration: 8000,
        });
      }

      enqueueSnackbar(t("The package has been removed."), {
        variant: "success",
        autoHideDuration: 8000,
      });

      filterTeachers(id);

    } catch (error) {
      enqueueSnackbar(t("The package has been removed."), {
        variant: "error",
        autoHideDuration: 8000,
      });
    }
  }

  function filterTeachers(id) {
    const filteredTeachers = packages.filter(
      (teacher) => teacher.id.toString() !== id.toString()
    );
    setPackages(filteredTeachers);
  }

  return (
    <Navbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "80px",
        }}
      >
      </Box>
      {!isLoading ? (
        <Paper sx={{ width: "100%", padding: "20px" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableBody>
                {packages.length > 0 &&
                  packages
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" key={row.id + "demj"}>
                          <TableCell align="center">
                            <img src={row.image ? `${process.env.REACT_APP_API_KEY}images/${row.image}` : "/logo.png"} alt={row.image} width={"100px"} />
                          </TableCell>
                          {lang === "ar" ?
                            <>
                              <TableCell align="center">
                                {row.titleAr || ""}
                              </TableCell>
                            </> : <>
                              <TableCell align="center">
                                {row.titleEn || ""}
                              </TableCell>
                            </>}
                          <TableCell align="center">{row.price}</TableCell>
                          {lang === "ar" ?
                            <>
                              <TableCell align="center">
                                {row.descriptionAr || ""}
                              </TableCell>
                            </> : <>
                              <TableCell align="center">
                                {row.descriptionEn || ""}
                              </TableCell>
                            </>}
                          {row.isVerified ? <>
                            <TableCell align="center" >
                              {t("Available")}
                            </TableCell>
                          </> : <>
                            <TableCell align="center">
                              {t("Review")}
                            </TableCell>
                          </>}
                          <TableCell align="center">{convertDate(row.startDate)}</TableCell>
                          <TableCell align="center">{convertDate(row.endDate)}</TableCell>
                          <TableCell align="center">
                            <Button
                              color="info"
                              onClick={() => EditPackage(row.id)}
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              color="error"
                              onClick={() => DeletePackage(row.id)}
                            >
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={packages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loading />
      )}
    </Navbar>
  );
}


