import React from 'react';
import StudentLayout from '../../components/student/StudentLayout'
import instance from '../../axios/instance';
import { useSelector } from 'react-redux';
import { Paper, styled, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@mui/material';
import { t } from 'i18next';
import Loading from '../../components/Loading';
import Cookies from "js-cookie";


const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(2),
    },
}));

function StudentDiscount() {
    const lang = Cookies.get("i18next") || "en";
    const columns = [
        { id: "image", label: t("Photo"), minWidth: 150 },
        { id: "name_course_ar", label: t("title"), minWidth: 150 },
        { id: "description_course_en", label: t("description"), minWidth: 150 },
        { id: "price", label: t("price"), minWidth: 150 },
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const { student } = useSelector(state => state.student);
    const [data, setData] = React.useState(null);
    React.useEffect(() => {
        (async () => {
            const res = await instance(`student/getMyDiscounts/${student.id}`);
            setData(res.data.data);
        })();
    }, [student.id]);
    console.log(data);

    if (data === null) return <Loading />
    else if (!data.length) return <StudentLayout><h1 className='text-center text-2xl text-sky-500 mt-20'>{t("Discounts are not available now")}</h1></StudentLayout>
    else return (
        <StudentLayout>
            <Root>
                <Paper sx={{ width: "100%", padding: "20px" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={"center"}
                                        style={{ top: 57, minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {data
                                    ?.filter((row) => {
                                        return lang === "ar" || lang === "en"
                                            ? row.discount?.titleArabic && row.discount?.descriptionArabic
                                            : row.discount?.titleEnglish && row.discount?.descriptionEnglish;
                                    })
                                    ?.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" key={row.id + "demj"}>
                                                <TableCell align="center"><img src={row.discount?.image ? `${process.env.REACT_APP_API_KEY}images/${row.discount?.image}` : "/logo.png"} alt={row.discount?.image} width={"100px"} /></TableCell>
                                                {
                                                    
                                                    lang === "ar" ?
                                                        <>
                                                            <TableCell align="center">{row.discount?.titleArabic}</TableCell>
                                                            <TableCell align="center">{row.discount?.descriptionArabic}</TableCell>

                                                        </>
                                                        :
                                                        <>
                                                            <TableCell align="center">{row.discount?.titleEnglish}</TableCell>
                                                            <TableCell align="center">{row.discount?.descriptionEnglish}</TableCell>
                                                        </>
                                                }
                                                <TableCell align="center">{row.discount?.amountAfterDiscount} </TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Root>
        </StudentLayout>
    )
}

export default StudentDiscount;