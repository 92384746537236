import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";
import axios from "axios";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { useSnackbar } from "notistack";
import StudentLayout from "../../components/student/StudentLayout";
import { loginStudent } from "../../redux/studentSlice";
import { useNavigate } from "react-router-dom";

export default function StudentNotify() {
  const {closeSnackbar,enqueueSnackbar} = useSnackbar()    
  const { t } = useTranslation();
  const { student, token } = useSelector((s) => s.student);
  const [notificationEnabled, setNotificationEnabled] = useState(student.isnotify); // Default value
  const navigate = useNavigate();

  // const fetchNotificationSetting = async () => {
  //   try {
  //     if (student && typeof student.isnotify !== "undefined") {
  //       setNotificationEnabled(student.isnotify);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching notification settings:", error);
  //   }
  // };
  const dispatch = useDispatch();

  const updateNotificationSetting = async (newValue) => {
    closeSnackbar();
    try {
        const res=await axios.post(`${process.env.REACT_APP_API_KEY}api/v1/student/setting/${student.id}`,
            {
                isnotify: newValue,
            },
            {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            });
      dispatch(loginStudent({ student: res.data.data.student }));      
      enqueueSnackbar(t("Notification settings updated successfully."),{variant:"success",autoHideDuration:8000})
    } catch (error) {
        console.log(error);

      enqueueSnackbar(t("Error updating notification settings"),{variant:"error",autoHideDuration:8000})
    }
  };

  // useEffect(() => {
  //   if (student && typeof student.isnotify !== "undefined") {
  //     fetchNotificationSetting();
  //   }
  // }, [student]);

  const handleOptionChange = async (event) => {
    const newValue = event.target.value === "true";
    setNotificationEnabled(newValue);
    await updateNotificationSetting(newValue);
  };
  console.log(notificationEnabled);
  

  return (
    <StudentLayout>
      {notificationEnabled !== null ? (
        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "400px",
            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t("Notifications")}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              aria-label="notification-settings"
              name="notifications"
              value={String(notificationEnabled)} // Convert to string
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={t("Yes")}
                sx={{ marginBottom: "8px", color: "#333" }}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={t("No")}
                sx={{ marginBottom: "8px", color: "#333" }}
              />
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button variant="outlined" color="error" onClick={()=>navigate('/student/profile')}>
              {t("Back ")}
            </Button>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </StudentLayout>
  );
}
