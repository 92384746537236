import React from 'react'
import Navbar from '../../Navbar'
import LinksFooter from '../home/LinksFooter'
import Footer from '../home/Footer'
import AdvertisementData from './AdvertisementData'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function AdvertisementDetailes() {
      const { t } = useTranslation();
      const navigate = useNavigate();
    
    return (
        <Navbar>
        <Typography 
        variant="h6" 
        sx={{
            mt:15,
            mb:2,
            mr:3,
            textAlign:"end",
            color:"blue",
            cursor:"pointer"
        }} 
        onClick={() => navigate("/")}

        >
            {t("Back")}
        </Typography>
            <AdvertisementData />
            <LinksFooter/>
            <Footer/>
        </Navbar>
    )
}