import {  Box, CardMedia, Paper, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next'
export default function AboutResource({ data }) {
    console.log(data);

    const { t } = useTranslation()
    const lang = Cookies.get("i18next") || "en";
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
      }
    return (
        <Paper sx={{ padding: "32px 24px", marginY: "30px" }}>
            <CardMedia
                component="img"
                height="140"
                image={data.image ? `${process.env.REACT_APP_API_KEY}images/${data.image}` : "/logo.png"}
                alt="package img"
                sx={{ filter: "brightness(50%)", height: "400px" }}
            />
                <Typography
                   variant="h4"
                    sx={{ marginBottom: "20px",marginTop:"8px", fontWeight: "700" }}
                >
                  {t(data.resource)}
                </Typography>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("level")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                    {
                        lang==="ar"?
                        <>{data.level.titleAR}</>
                        :
                        <>{data.level.titleEN}</>
                    }
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("subject")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2"  }}
                >
                    {
                        lang==="ar"?
                        <>{data.subject.titleAR}</>
                        :
                        <>{data.subject.titleEN}</>
                    }
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", fontWeight: "700", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center" }}
                >
                    {t("price")}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{  marginBottom: "8px", fontWeight: "400", background: "#f2f3f4",borderRadius:"1rem" ,padding:"1rem 5rem",width:"300px",textAlign:"center",color:"#5dade2" }}
                >
                     {data.price} {t(data.currency)} 
                </Typography>
            </Box>
        </Paper>

    )
}
