import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { t } from "i18next";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import TeacherCard from "./../../reusableUi/TeacherCard";
import { useSelector } from 'react-redux';
import useCurrency from "./../../../hooks/useCurrency";
import Cookies from "js-cookie";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Carousel from 'react-material-ui-carousel';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Packages() {
  const [search, setSearch] = React.useState("");
  const [packages, setAllPackages] = React.useState([]);

  function packageSearch(e) {
    const eleValue = e.target.value;
    setSearch(eleValue);
  }
  React.useEffect(() => {
    (async () => {
      const response = await instance.get("packages/get-packages");
      const data = response.data.data || [];
      setAllPackages([...data]);
    })();
  }, []);

  return (
    <Container sx={{ marginTop: "100px" }}>
      <h1 className='text-center text-5xl mb-6 mt-5' style={{ marginBottom: "60px" }}>{t("show Package")}</h1>
      {
        !packages.length ?
          <h1 className='text-2xl text-sky-500 mt-10 text-center'>
            {t("No Package")}
          </h1>
          :
          <Carousel
            navButtonsAlwaysVisible={true}
            NextIcon={<ArrowForwardIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
            PrevIcon={<ArrowBackIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
          >
            {packages.map((subject) => (
              <div key={subject.id}>
                <PackageCard subject={subject} />
              </div>
            ))}
          </Carousel>
      }
    </Container>
  )
}

export default Packages;

function PackageCard({ subject }) {
  console.log(subject);

  const MINUTE = 60;
  const navigate = useNavigate();
  const { currency } = useSelector(state => state.currency);
  const currencyObj = useCurrency(currency);
  const lang = Cookies.get("i18next") || "en";
  const { conversionRate } = useSelector(state => state.conversionRate);
  function convertDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // yyyy-mm-dd
  }
  
  return (
    <>
      <Card
        key={subject.id}
        sx={{
          margin: "0rem auto 2rem",
          padding: "1rem",
          maxWidth: 600,
          maxHeight: 1000,
          boxShadow: 3,
        }}
      >
        {/* صورة الباقة */}
        <CardMedia
          component="img"
          height="140"
          image={subject.image ? `${process.env.REACT_APP_API_KEY}images/${subject.image}` : "/logo.png"}
          alt="package img"
          sx={{ filter: "brightness(50%)", height: "140px" }}
        />

        {/* العنوان */}
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: "yellow",
            fontWeight: "bold",
            marginTop: "0px",
            textShadow: "1px 1px 4px black",
          }}
        >
          {lang === "ar" ?
            <>{subject.titleAr}</>
            :
            <>{subject.titleEn}</>
          }
        </Typography>

        {/* تفاصيل الباقة */}
        <CardContent>

          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {`${t("level")}: `}
            {lang === "ar" ?
              <>{subject.level.titleAR}</>
              :
              <>{subject.level.titleEN}</>
            }
          </Typography>

          <ul style={{ listStyleType: "disc", paddingLeft: "1.5rem", marginBottom: "1.5rem", color: "#555" }}>
            <li>{`${t("sharesCountInWeek")} ${subject.sharesInWeek}`}</li>
            <li>{`${t("sharesCount")} ${subject.numberOfShares}`}</li>
            <li>{`${t("durationShares")} ${subject.duration * 60} ${t("minute")}`}</li>
            <li>
              {t("packagePlace")} <span style={{ textDecoration: "underline" }}>{t(`${subject.packageType}_place`)}</span>
            </li>
            <li>{`${t("startDate")}: ${convertDate(subject.startDate)}`}</li>
            <li>{`${t("endDate")}: ${convertDate(subject.endDate)}`}</li>
          </ul>

          {/* زر العرض */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate(`/teacher/${subject.teacherId}/${subject.id}`)}
            sx={{ marginBottom: "1rem" }}
          >
            {`${subject.numberOfShares} ${t("shares")} (${t("sharePrice")} ${subject.price * conversionRate} ${lang === "en" ? currencyObj.titleEn : currencyObj.titleAr
              })`}
          </Button>

          {/* حوار المعلم */}
          <TeacherDialog buttonName={t("teacher")} teacherId={subject.teacherId} />
        </CardContent>
      </Card>
    </>
  );

}

function TeacherDialog({ buttonName, teacherId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [teacher, setTeacher] = React.useState();

  const handleClickOpen = async () => {
    try {
      const response = await instance.get("teacher/getSingleTeacher/" + teacherId);
      setTeacher(response.data.data);
      setOpen(true);
    } catch (err) {
      enqueueSnackbar(t("error"), { variant: "error", autoHideDuration: "5000" });
      throw new Error("Server not responding. " + err);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return <React.Fragment>
    <Button variant="contained" fullWidth onClick={handleClickOpen}>
      {buttonName}
    </Button>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {
        open ? <TeacherCard teacher={teacher} /> : ""
      }
    </Dialog>
  </React.Fragment>
}