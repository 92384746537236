import {useQuery} from 'react-query'

async function getWaitingJob()
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/job/pending-job`
    )
    return response.json()
}

export const useWaitingJobs = ()=>
{
    return useQuery('get-waiting-jobs',()=>getWaitingJob())
}