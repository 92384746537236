import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar';
import AddQuestion from '../../components/teacher/AddQuestion';
import QuestionsCategories from '../../components/teacher/QuestionsCategories';

export default function TeacherQuestion() {
    const {t} = useTranslation()
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Navbar>
            <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider',marginTop:"100px" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={t('View Questions')} value="1" />
                        <Tab label={t('Add New Question')} value="2" />
                    </TabList>
                </Box>
                    <TabPanel value="1"><QuestionsCategories/></TabPanel>
                    <TabPanel value="2"><AddQuestion/></TabPanel>
                </TabContext>
        </Navbar>
    )
}