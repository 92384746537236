import { Container, Paper } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import instance from '../../axios/instance';
import Navbar from '../../components/Navbar';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import TeacherCard from '../../components/reusableUi/TeacherCard';
import SearchBar from '../../components/reusableUi/SearchBar';

function AllSpecialties() {
  const [teachers, setTeachers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = React.useState();
  React.useEffect(() => {
    (async () => {
      try {
        const response = await instance("teacher/allTeachers/");
        setIsLoading(false);
        setTeachers([...response.data]);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error", autoHideDuration: "5000" });
      }
    })();
  }, [enqueueSnackbar]);

  return (
    <Navbar>
      <Container sx={{ marginTop: "100px", marginBottom: "100px" }}>
        <Paper sx={{ padding: "60px", textAlign: "center", backgroundColor: "#005B8E" }}> {/* Title */}
          <h1 className='text-3xl text-white'>{t("allTeachers")}</h1>
        </Paper>
        {/* Search bar */}
        <SearchBar setValue={setSearch} text={"searchSpecialties"} />
        {
          isLoading ?
            <Loading />
            :
            <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-6'>
              {
                teachers.length ?
                  teachers.map((teacher) => {
                    // Flag country icon.
                    if (teacher.firstName && teacher.firstName) {
                      // For search functionality.
                      const teacherName = (`${teacher.firstName} ${teacher.lastName}`.toLowerCase()).includes(search);
                      const teacherHeadlineAr = (teacher.shortHeadlineAr.toLowerCase()).includes(search);
                      const teacherHeadlineEn = (teacher.shortHeadlineEn.toLowerCase()).includes(search);
                      if (search) {
                        if (teacherName || teacherHeadlineAr || teacherHeadlineEn) {
                          return <TeacherCard teacher={teacher} />
                        }
                        return false;
                      } else {
                        return <TeacherCard teacher={teacher} />
                      }
                    }
                    return false;
                  }) : <h1 className='text-red-500/50 text-center text-red-500 text-2xl'>Something went wrong</h1>
              }
            </div>
        }
      </Container>
    </Navbar>
  )
}

export default AllSpecialties;