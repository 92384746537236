import {
    Button,
    Container,
    Paper,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SendEmail from "./SendEmail";

export default function SelectGuest() {
    const [showGest, setShowGust] = useState(true);
    const [selectType, setSelectType] = useState();
    const { t } = useTranslation();
    const handelSelecte = (e) => {
        if (e === "job") {
            setSelectType("job");
            setShowGust(false);
        } else if (e === "advertisement") {
            setSelectType("advertisement");
            setShowGust(false);

        }
    }

    return (
        <>
            {showGest ? <Container sx={{ marginTop: "110px", display: "flex", textAlign: "center" }}>
                <Paper
                    sx={{
                        width: { md: "600px" },
                        padding: "30px 50px",
                        margin: "60px auto 400px",
                    }}
                >
                    <Typography
                        sx={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "center",
                            fontWeight: "700",
                            marginBottom: "20px",
                        }}
                    >
                        {t("What type of ad do you want?")}
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => handelSelecte("advertisement")}
                        sx={{
                            textTransform: "capitalize",
                            margin: "15px",
                            color:"blue",
                            width: { md: "150px" },
                            height: { md: "70px" },
                            cursor: "pointer",
                            backgroundColor: "#f1c40f"
                        }}
                    >
                        {t("Add Your Ad Here")}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handelSelecte("job")}
                        sx={{
                            textTransform: "capitalize",
                            margin: "15px",
                            width: { md: "150px" },
                            height: { md: "70px" },
                            cursor: "pointer",
                        }}
                    >
                        {t("Job")}
                    </Button>
                </Paper>
            </Container> : <SendEmail type={selectType} />}
        </>

    );
}
