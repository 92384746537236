import { Box } from '@mui/material'
import React from 'react'
import AdminLayout from '../../components/admin/AdminLayout'
import MainBoxes from '../../components/admin/MainBoxes'
import NewStudentsList from '../../components/admin/NewStudentsList'
import { useSelector } from 'react-redux'
import { t } from 'i18next'

export default function AdminHome() {
    const { admin } = useSelector((state) => state.admin);

    return (
        <AdminLayout>
            <h1 className='text-blue-900 font-bold'>{admin.name}</h1>
            <h3 className='text-red-300'>{`${t("adminDate")}: ${admin.loginDate}`}</h3>
            <Box sx={{ marginY: "40px" }}>
                <MainBoxes />
                <NewStudentsList />
            </Box>
        </AdminLayout>
    )
}
