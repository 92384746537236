import {useQuery} from 'react-query'

async function getAnswers(teacherId)
{
    const response = await fetch(`${process.env.REACT_APP_API_KEY}api/v1/teacher/answers/single-teacher/${teacherId}`)
    
    return response.json()
}

export const useAllAnswers = (teacherId)=>
{
    return useQuery(['get-teacher-question-answer',teacherId],()=>getAnswers(teacherId))
}