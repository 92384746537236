import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import instance from '../../../axios/instance';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { t } from "i18next";
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import TeacherCard from "./../../reusableUi/TeacherCard";
import { useSelector } from 'react-redux';
import useCurrency from "./../../../hooks/useCurrency";
import Cookies from "js-cookie";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Carousel from 'react-material-ui-carousel';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ResourceHome() {
    const [search, setSearch] = React.useState("");
    const [packages, setAllPackages] = React.useState([]);

    function packageSearch(e) {
        const eleValue = e.target.value;
        setSearch(eleValue);
    }
    React.useEffect(() => {
        (async () => {
            const response = await instance.get("resources/status/accept-resources");
            const data = response.data.data || [];
            setAllPackages([...data]);
        })();
    }, []);
    console.log(packages);

    return (
        <Container sx={{ marginTop: "100px" }}>
            <h1 className='text-center text-5xl mb-6 mt-5' style={{ marginBottom: "60px" }}>{t("show Resources")}</h1>
            {
                !packages.length ?
                    <h1 className='text-2xl text-sky-500 mt-10 text-center'>
                        {t("No Resources")}
                    </h1>
                    :
                    <Carousel
                        navButtonsAlwaysVisible={true}
                        NextIcon={<ArrowForwardIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
                        PrevIcon={<ArrowBackIosIcon style={{ color: "#3498db", fontSize: "30px" }} />}
                    >
                        {packages.map((subject) => (
                            <div key={subject.id}>
                                <PackageCard subject={subject} />
                            </div>
                        ))}
                    </Carousel>
            }
        </Container>
    )
}

export default ResourceHome;

function PackageCard({ subject }) {
    console.log(subject);

    const MINUTE = 60;
    const navigate = useNavigate();
    const { currency } = useSelector(state => state.currency);
    const currencyObj = useCurrency(currency);
    const lang = Cookies.get("i18next") || "en";
    const { conversionRate } = useSelector(state => state.conversionRate);
    function convertDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // yyyy-mm-dd
    }
    return (
        <>
            <Card
                key={subject.id}
                sx={{
                    margin: "0rem auto 2rem",
                    padding: "1rem",
                    maxWidth: 600,
                    maxHeight: 1000,
                    boxShadow: 3,
                }}
            >
                {/* صورة الباقة */}
                <CardMedia
                    component="img"
                    height="140"
                    image={subject.image ? `${process.env.REACT_APP_API_KEY}images/${subject.image}` : "/logo.png"}
                    alt="package img"
                    sx={{ filter: "brightness(50%)", height: "140px" }}
                />

                {/* العنوان */}
                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "center",
                        color: "yellow",
                        fontWeight: "bold",
                        marginTop: "0px",
                        textShadow: "1px 1px 4px black",
                    }}
                >
                    {t(subject.resource)}
                </Typography>

                {/* تفاصيل الباقة */}
                <CardContent>
                <Typography variant="body2" sx={{ marginBottom: 1,display:"flex",justifyContent:"space-around" }}>
                <Typography variant="body2" sx={{ marginBottom: 1 }}>
                        <Typography variant="body2" sx={{ marginBottom: 1 }}>
                            {`${t("level")}: `}
                            {lang === "ar" ?
                                <>{subject.level.titleAR}</>
                                :
                                <>{subject.level.titleEN}</>
                            }
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: 3 }}>
                            {`${t("subject")}: `}
                            {lang === "ar" ?
                                <>{subject.subject.titleAR}</>
                                :
                                <>{subject.subject.titleEN}</>
                            }
                        </Typography>
                    </Typography>

                    <Typography variant="body2" sx={{ marginBottom: 1,textAlign:"center" }}>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                            {`${t("price")} `}
                        </Typography>
                        <Typography variant="body2" sx={{ marginBottom: 3,color:"red"}}>
                        {subject.price} {t(subject.currency)}
                        </Typography>
                    </Typography>
                </Typography>

                    
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => navigate(`/resource/${subject.teacherId}/${subject.id}`)}
                        sx={{ marginBottom: "1rem" }}
                    >
                        {t("View more")}
                    </Button>
                </CardContent>
            </Card>
        </>
    );

}
