import React from "react";
import DownloadApp from "../../components/client/home/DownloadApp";
import Footer from "../../components/client/home/Footer";
import HomeAbout from "../../components/client/home/HomeAbout";
import HomeBanner from "../../components/client/home/HomeBanner";
import HomeImages from "../../components/client/home/HomeImages";
import HomeQuestions from "../../components/client/home/HomeQuestions";
import HomeWorks from "../../components/client/home/HomeWorks";
import LinksFooter from "../../components/client/home/LinksFooter";
import Navbar from "../../components/Navbar";
import { useSelector } from "react-redux";
import MapBrowsing from "../../components/client/home/MapBrowsing";
import TeacherTab from "../../components/client/home/TeacherTab";
import Rates from "../../components/client/home/Rates";
import Packages from "../../components/client/home/Packages";
import Discounts from "../../components/client/home/Discounts";
import Advertisements from "../../components/client/home/Advertisements";
import ResourceHome from "../../components/client/home/ResourceHome";
import Lectures from "../../components/client/home/Lectures";
import Test from "../../components/client/home/Test";

export default function Home() {
  const { student } = useSelector((state) => state.student);
  return (
    <Navbar>
      {student && student.lat && student.long && <MapBrowsing />}
      <HomeBanner />
      {/* ///// new */}
      <TeacherTab />
      {/* //////// */}
      <HomeImages />
      {/* ///// new */}
      <Rates />
      <Packages />
      {/* ////////sabry */}
      <Discounts />
      {/* Test */}
      <Test />
      {/* Lectures */}
      <Lectures />
      <ResourceHome/>
      <Advertisements />

      {/* //////// */}
      <HomeWorks />
      <HomeAbout />
      <HomeQuestions />
      <DownloadApp />
      <LinksFooter />
      <Footer />
    </Navbar>
  );
}
