import {
    Box,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useMyTeacherLectures } from "../../hooks/useMyTeacherLectures";
import Loading from "../Loading";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AddQuestion({ handleClose }) {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const { teacher } = useSelector((state) => state.teacher);
    const [lectures, setLectures] = useState([])

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            lectureId: "",
            titleArabic: "",
            titleEnglish: "",
            descriptionArabic: "",
            descriptionEnglish: ""
        },
    });

    const { data, isLoading } = useMyTeacherLectures(teacher.id);
    useEffect(() => {
        if (data?.data) {
            setLectures(data.data);
        }
    }, [data]);
    const navigate = useNavigate();
    const lang = Cookies.get("i18next") || "en";
    async function onSubmit(data) {
        closeSnackbar();
        const formData = new FormData();

        formData.append("teacherId", teacher.id);
        for (const key in data) {
            if (key !== "teacherId") {
                formData.append(key, data[key]);
            }
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API_KEY}api/v1/teacher/questions/create-question`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            enqueueSnackbar(t("A new question has been created."), { variant: "success", autoHideDuration: 8000 });
            navigate("/teacher/questions")
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("Something went wrong"), { variant: "error", autoHideDuration: 8000 });
        }
    }
    
    return (
        <>
            {!isLoading ? <>
                <Box sx={{ width: "500px", maxWidth: "100%" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ marginBottom: "18px" }}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                    {t("Select Lecture")}
                                </InputLabel>
                                <Select
                                    labelId="lectureId"
                                    label={t("Lecture")}
                                    defaultValue="" // قيمة افتراضية
                                    {...register("lectureId", {
                                        required: t("isRequired"),
                                    })}
                                >
                                    {lang === "ar"
                                        ? lectures.map((lecture, index) => (
                                            <MenuItem key={index} value={lecture.id}>{t(lecture.titleArabic)}</MenuItem>
                                        ))
                                        : lectures.map((lecture, index) => (
                                            <MenuItem key={index} value={lecture.id}>{t(lecture.tittitleEnglish)}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box >
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("titleAr")}
                            </InputLabel>
                            <Controller
                                name="titleArabic"
                                control={control}
                                render={({ field }) => <TextField {...field} fullWidth />}
                                {...register("titleArabic", {
                                    required: "title Address is required",
                                })}
                            />
                            {errors.titleArabic?.type === "required" && (
                                <Typography
                                    color="error"
                                    role="alert"
                                    sx={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                    {t("required")}
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("titleEn")}
                            </InputLabel>
                            <Controller
                                name="titleEnglish"
                                control={control}
                                render={({ field }) => <TextField {...field} fullWidth />}
                                {...register("titleEnglish", {
                                    required: "title Address is required",
                                })}
                            />
                            {errors.titleEnglish?.type === "required" && (
                                <Typography
                                    color="error"
                                    role="alert"
                                    sx={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                    {t("required")}
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("descriptionQuestionArabic")}
                            </InputLabel>
                            <Controller
                                name="descriptionArabic"
                                control={control}
                                render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                {...register("descriptionArabic", {
                                    required: "description Address is required",
                                })}
                            />
                            {errors.descriptionArabic?.type === "required" && (
                                <Typography
                                    color="error"
                                    role="alert"
                                    sx={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                    {t("required")}
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ marginBottom: "18px" }}>
                            <InputLabel sx={{ marginBottom: "6px", fontSize: "14px" }}>
                                {t("descriptionQuestionEnglish")}
                            </InputLabel>
                            <Controller
                                name="descriptionEnglish"
                                control={control}
                                render={({ field }) => <TextField multiline rows={4} {...field} fullWidth />}
                                {...register("descriptionEnglish", {
                                    required: "description Address is required",
                                })}
                            />
                            {errors.descriptionEnglish?.type === "required" && (
                                <Typography
                                    color="error"
                                    role="alert"
                                    sx={{ fontSize: "13px", marginTop: "6px" }}
                                >
                                    {t("required")}
                                </Typography>
                            )}
                        </Box>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ ml: "6px", mr: "6px" }}
                            >
                                {t("save")}
                            </Button>
                            <Button onClick={handleClose} color="error">
                                {t("cancel")}
                            </Button>
                        </DialogActions>
                    </form>

                </Box>
            </> : <>
                <Loading />
            </>}

        </>
    );
}
